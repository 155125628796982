import { entity } from "simpler-state";

const defaultState: {
  invitationCode: string | undefined;
} = {
  invitationCode: "",
};

export const brandInvitationEntity = entity<{
  invitationCode: string | undefined;
}>(defaultState);

export const setCode = (code: string | undefined) =>
  brandInvitationEntity.set((currentState) => ({
    ...currentState,
    invitationCode: code,
  }));
