import { entity, persistence } from "simpler-state";
import { fetchFromApi, handleFetchError } from "../services";
import { requestEnd, requestStart } from "./app.entity";
import { capitalize } from "../utils/helpers";

export interface IPlatform {
  code: string;
  currency: string;
  currencyCode: string;
  currencySymbol: string;
  language: string;
  languageCode: string;
  name: string;
  _id: string;
  allowPartnersToWithdrawEarning: boolean;
}
const initState: IPlatform[] = [];

export const platformEntity = entity<IPlatform[]>(initState, [
  persistence("marketplaces"),
]);
export const setPlatform = (platform: IPlatform[]) =>
  platformEntity.set(platform);

export const fetchPlatform = async () => {
  await fetchInfo("");
};

export const fetchPlatformPublic = async () => {
  await fetchInfo("public");
};

const fetchInfo = async (type: string) => {
  const currentPlatform = platformEntity.get();
  requestStart("fetch-marketplace");
  try {
    const { data } = await fetchFromApi({
      path: `platform/${type}`,
    });

    if (JSON.stringify(currentPlatform) !== JSON.stringify(data.marketplaces))
      setPlatform(data.marketplaces);
  } catch (error) {
    handleFetchError(error);
  } finally {
    requestEnd();
  }
};

export const languages = [
  ...new Set(platformEntity.get().map((el) => el.language)),
];
export const languageCodes = [
  ...new Set(platformEntity.get().map((el) => el.languageCode)),
];
export const marketplaces = [
  ...new Set(platformEntity.get().map((el) => el.name)),
];
export const usePlatformData = () => {
  const platformData = platformEntity.use();
  return {
    marketplaces: [...new Set(platformData.map((el) => el.name))],
    currencies: [...new Set(platformData.map((el) => el.currency))],
    languages: [...new Set(platformData.map((el) => el.language))],
  };
};

export const currencies = [
  ...new Set(platformEntity.get().map((el) => el.currency)),
];

export const getCountryCode = (country: string) =>
  [...new Set(platformEntity.get())]
    .find((c) => c.name.toLowerCase() === country.toLowerCase())
    ?.code.toLowerCase();

export const getCountryByCode = (code: string, cap?: "capitalize") => {
  if (!code) return;
  const country = [...new Set(platformEntity.get())].find(
    (c) => c.code.toLowerCase() === code.toLowerCase()
  );
  return country?.name && !cap
    ? country.name.toLowerCase()
    : country?.name && cap == "capitalize"
    ? capitalize(country.name)
    : "";
};

export const getLanguageCode = (language: string) =>
  [...new Set(platformEntity.get())]
    .find((c) => c?.language.toLowerCase() === language?.toLowerCase())
    ?.languageCode.toLowerCase();

export const getLanguageByCode = (code: string) =>
  [...new Set(platformEntity.get())]
    .find((c) => c?.languageCode.toLowerCase() === code?.toLowerCase())
    ?.language.toLowerCase();

export const getCurrencyByCountryCode = (code: string) =>
  [...new Set(platformEntity.get())]
    .find((c) => c?.code.toLowerCase() === code?.toLowerCase())
    ?.currencySymbol.toLowerCase();

export const getCurrencySymbolByCode = (code: string) =>
  [...new Set(platformEntity.get())]
    .find((c) => c?.currencyCode?.toLowerCase() === code?.toLowerCase())
    ?.currencySymbol.toLowerCase();
