import { lazy } from "react";
import { AppRouteObject } from "./appNavigation.types";
import { Stylguide } from "../pages/Styleguide";
import VerifyBrandInvitation from "../pages/AuthPages/BrandInvitation/VerifyBrandInvitation";
import AcceptBrandInvitation from "../pages/AuthPages/BrandInvitation/AcceptBrandInvitation";

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function <T>(componentImport: () => Promise<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    // Check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem("retry-lazy-refreshed") || "false"
    );

    // Try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem("retry-lazy-refreshed", "false"); // Success, so reset the refresh flag
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // Not been refreshed yet
          window.sessionStorage.setItem("retry-lazy-refreshed", "true"); // Set flag to indicate refresh
          window.location.reload(); // Refresh the page
        } else {
          // Already tried refresh, reject with the error
          reject(error);
        }
      });
  });
};

export default lazyRetry;

// const AuditLogPage = lazy(() => lazyRetry(() => import("../pages/AuditLog")));
const ForgotPasswordPage = lazy(() =>
  lazyRetry(() => import("../pages/AuthPages/ForgotPassword"))
);
const LoginPage = lazy(() =>
  lazyRetry(() => import("../pages/AuthPages/Login"))
);
const RegisterPage = lazy(() =>
  lazyRetry(() => import("../pages/AuthPages/PartnerRegister"))
);
const BrandSignup = lazy(() =>
  lazyRetry(() => import("../pages/AuthPages/BrandSignup"))
);
const CategoriesPage = lazy(() =>
  lazyRetry(() => import("../pages/Categories"))
);
const CmsPage = lazy(() =>
  lazyRetry(() => import("../pages/ContentManagement"))
);
const DrivePage = lazy(() => lazyRetry(() => import("../pages/Drive")));
const DashboardPage = lazy(() => lazyRetry(() => import("../pages/Home")));
const SettingsPage = lazy(() => lazyRetry(() => import("../pages/Settings")));
const StatisticsPage = lazy(() =>
  lazyRetry(() => import("../pages/Statistics"))
);
const SuppliersPage = lazy(() => lazyRetry(() => import("../pages/Suppliers")));
const TransactionsPage = lazy(() =>
  lazyRetry(() => import("../pages/Transactions"))
);
const UsersPage = lazy(() => lazyRetry(() => import("../pages/Users")));
const AcceptInvitation = lazy(() =>
  lazyRetry(() => import("../pages/AuthPages/AcceptInvitation"))
);
const ResetPasswordPage = lazy(() =>
  lazyRetry(() => import("../pages/AuthPages/ResetPassword"))
);
const Vendors = lazy(() =>
  lazyRetry(() => import("../pages/BrandPanel/Vendors"))
);
const SingleVendor = lazy(() =>
  lazyRetry(() => import("../pages/BrandPanel/Vendors/SingleVendor"))
);
const SingleProduct = lazy(() =>
  lazyRetry(() => import("../pages/Products/SingleProduct"))
);
const AddProduct = lazy(() =>
  lazyRetry(() => import("../pages/Products/AddProduct"))
);
const SupplierDetail = lazy(() =>
  lazyRetry(() => import("../pages/Suppliers/SupplierDetail"))
);
const LandingPage = lazy(() => lazyRetry(() => import("../pages/LandingPage")));
const ProductsPage = lazy(() => lazyRetry(() => import("../pages/Products")));
const BrandProductsPage = lazy(() =>
  lazyRetry(() => import("../pages/BrandPanel/Products"))
);
const SinglePendingSupplier = lazy(() =>
  lazyRetry(
    () => import("../pages/Suppliers/PendingSuppliers/SinglePendingSupplier")
  )
);
const PayoutDetailPage = lazy(() =>
  lazyRetry(() => import("../pages/Transactions/Payouts/PayoutDetails"))
);
const BrandsPage = lazy(() => lazyRetry(() => import("../pages/Brands")));
const BrandDetails = lazy(() =>
  lazyRetry(() => import("../pages/Brands/BrandDetail"))
);
const ComingSoon = lazy(() =>
  lazyRetry(() => import("../pages/AuthPages/PartnerRegister/ComingSoon"))
);
const RegistrationSuccess = lazy(() =>
  lazyRetry(() => import("../pages/AuthPages/PartnerRegister/RegisterSuccess"))
);
// const StaffManagement = lazy(() =>
//   lazyRetry(() => import("../pages/StaffManagement"))
// );

// const Support = lazy(() => lazyRetry(() => import("../pages/Support")));

export const allAppRoutes: Array<AppRouteObject> = [
  {
    path: "/",
    element: <LandingPage />,
    noAuth: true,
    routeName: "Landing Page",
  },
  {
    path: "/auth/login",
    element: <LoginPage />,
    noAuth: true,
    routeName: "Login",
  },
  {
    path: "/auth/partner-signup",
    element: <RegisterPage />,
    noAuth: true,
    routeName: "Register",
  },
  {
    path: "/auth/brand/:brand",
    element: <BrandSignup />,
    noAuth: true,
    routeName: "Register",
  },
  {
    path: "/auth/football-club/:brand",
    element: <BrandSignup />,
    noAuth: true,
    routeName: "Register",
  },
  {
    path: "/auth/football-club/:brand/:category",
    element: <BrandSignup />,
    noAuth: true,
    routeName: "Register",
  },

  {
    path: "/auth/invitation/:code",
    element: <AcceptInvitation />,
    noAuth: true,
    routeName: "Invitation",
  },
  {
    path: "/auth/partner-invite/:code",
    element: <VerifyBrandInvitation />,
    noAuth: true,
    routeName: "Partner Invitation",
  },
  {
    path: "/auth/accept-brand-invitation/:brand",
    element: <AcceptBrandInvitation />,
    noAuth: true,
    routeName: "Invitation",
  },
  {
    path: "/auth/forgot-password",
    element: <ForgotPasswordPage />,
    noAuth: true,
    routeName: "Forgot Password",
  },
  {
    path: "/auth/reset-pass",
    element: <ResetPasswordPage />,
    noAuth: true,
    routeName: "Reset",
  },
  {
    path: "/dashboard",
    element: <DashboardPage />,
    routeName: "Dashboard",
    sideBarLink: true,
    icon: "heroicons:home",
  },
  {
    path: "/transactions",
    element: <TransactionsPage />,
    routeName: "Transactions",
    sideBarLink: true,
    icon: "heroicons:arrow-trending-up",
    show: "super-admin,country-admin,admin,partner-admin",
  },
  {
    path: "/transactions",
    element: <TransactionsPage />,
    routeName: "Orders",
    sideBarLink: true,
    icon: "heroicons:arrow-trending-up",
    show: "local-partner",
  },

  {
    path: "/vendors",
    element: <Vendors />,
    routeName: "Vendors",
    sideBarLink: true,
    icon: "octicon:people-24",
    show: "brand-admin",
  },
  {
    path: "/vendors/:vendor",
    element: <SingleVendor />,
    routeName: "Vendors",
    show: "brand-admin",
  },
  {
    path: "/brand-products",
    element: <BrandProductsPage />,
    routeName: "Products",
    sideBarLink: true,
    icon: "heroicons:gift",
    show: "brand-admin",
  },
  {
    path: "/transactions/payout/:id",
    element: <PayoutDetailPage />,
    routeName: "Transactions",
    sideBarLink: false,
    icon: "heroicons:arrow-trending-up",
  },
  // {
  //   path: "/transactions/voucher-codes/:id",
  //   element: <SingleVoucher />,
  //   routeName: "Transactions",
  //   sideBarLink: false,
  // },
  {
    path: "/statistics",
    element: <StatisticsPage />,
    routeName: "Statistics",
    sideBarLink: true,
    icon: "heroicons:chart-bar",
    show: "super-admin,country-admin,admin,partner-admin",
  },
  // {
  //   path: "/staff-management",
  //   element: <StaffManagement />,
  //   routeName: "Staff management",
  //   sideBarLink: true,
  //   icon: "heroicons:users",
  //   show: "partner-admin",
  // },
  {
    path: "/products",
    element: <ProductsPage />,
    routeName: "Products",
    sideBarLink: true,
    icon: "heroicons:gift",
    show: "super-admin,country-admin,admin,partner-admin,local-partner,pos-user",
  },
  {
    path: "/products/add-product",
    element: <AddProduct />,
    routeName: "Products",
    sideBarLink: false,
    icon: "heroicons:gift",
  },

  {
    path: "/products/:product",
    element: <SingleProduct />,
    routeName: "Products",
    sideBarLink: false,
    icon: "heroicons:gift",
  },
  {
    path: "/categories",
    element: <CategoriesPage />,
    routeName: "Categories",
    sideBarLink: true,
    show: "super-admin,country-admin,admin",
    icon: "heroicons:funnel",
  },
  {
    path: "/suppliers",
    element: <SuppliersPage />,
    routeName: "Suppliers",
    sideBarLink: true,
    icon: "heroicons:users",
    show: "super-admin,country-admin,admin",
  },
  {
    path: "/suppliers/:supplier",
    element: <SupplierDetail />,
    routeName: "Suppliers",
    sideBarLink: false,
    icon: "heroicons:users",
  },
  {
    path: "/brands",
    element: <BrandsPage />,
    routeName: "Brands",
    sideBarLink: true,
    icon: "heroicons:briefcase",
    show: "super-admin,country-admin,admin",
  },
  {
    path: "brands/:brand",
    element: <BrandDetails />,
    routeName: "Brands",
  },
  {
    path: "/users",
    element: <UsersPage />,
    routeName: "App users",
    sideBarLink: true,
    icon: "heroicons:user-group",
    show: "super-admin,country-admin,admin",
  },
  {
    path: "/drive",
    element: <DrivePage />,
    routeName: "Drive",
    sideBarLink: true,
    icon: "heroicons:folder-open",
    show: "super-admin,country-admin,admin,partner-admin",
  },
  {
    path: "/drive/:folder",
    element: <DrivePage />,
    routeName: "Drive",
    sideBarLink: false,
    icon: "heroicons:folder-open",
  },
  {
    path: "/cms",
    element: <CmsPage />,
    routeName: "CMS",
    sideBarLink: true,
    icon: "heroicons:book-open",
    show: "super-admin,country-admin,admin",
  },

  // {
  //   path: "/audit-log",
  //   element: <AuditLogPage />,
  //   routeName: "Audit Log",
  //   bottomSideLink: true,
  //   icon: "heroicons:eye",
  //   show: "super-admin,admin",
  // },
  // {
  //   path: "/support",
  //   element: <Support />,
  //   routeName: "Support",
  //   bottomSideLink: true,
  //   icon: "heroicons:question-mark-circle",
  // },
  {
    path: "/settings",
    element: <SettingsPage />,
    routeName: "Settings",
    bottomSideLink: true,
    icon: "heroicons:wrench-screwdriver",
  },

  {
    path: "/__styleguide",
    routeName: "Styleguide",
    element: <Stylguide />,
  },
  {
    path: "/",
    element: <DashboardPage />,
    routeName: "Dashboard",
  },
  {
    path: "/pending-suppliers/:id",
    element: <SinglePendingSupplier />,
    routeName: "PendingSupplier",
    show: "super-admin,country-admin,admin",
  },
  {
    path: "/coming-soon",
    element: <ComingSoon />,
    routeName: "ComingSoon",
  },
  {
    path: "/registration-success",
    element: <RegistrationSuccess />,
    routeName: "RegistrationSuccess",
  },
];
