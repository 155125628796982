import { Icon } from "@iconify/react";
import { useClickOutside } from "@reactuses/core";
import clsx from "clsx";
import { ComponentProps, FC, useEffect, useRef, useState } from "react";

const SlideModal: FC<
  ComponentProps<"div"> & {
    isOpen: boolean;
    close: () => void;
    fitEnd?: boolean;
    wrapperClassname?: string;
  }
> = ({ children, isOpen, close, title, wrapperClassname }) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => setIsActive(isOpen), [isOpen]);

  const onClose = () => {
    setIsActive(false);
    setTimeout(() => close(), 300);
  };

  useClickOutside(contentRef, () => onClose());

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 bg-darkest/20">
          <div
            ref={contentRef}
            className={clsx(
              "absolute transition-all will-change-auto ease-in-out h-full duration-300 bg-white inset-0 left-auto",
              !wrapperClassname
                ? `w-full sm:w-2/3 lg:w-1/2 xl:w-5/12 2xl:max-w-2xl `
                : wrapperClassname,
              isActive
                ? "translate-x-0 opacity-100"
                : "translate-x-full opacity-0"
            )}
          >
            <div className="flex items-center h-16 gap-3 px-4 border-b md:px-8">
              <p className="w-full text-lg font-bold">{title}</p>
              <button
                type="button"
                className="grid p-2 text-xl text-white rounded-full shrink-0 bg-dark place-items-center"
                onClick={onClose}
              >
                <Icon icon="tabler:x" />
              </button>
            </div>
            <div className="flex flex-col items-stretch overflow-auto md:p-8  ">
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SlideModal;
