import SupportMenu from "./SupportMenu";
import {
  supportEntity,
  toggleSupportButton,
  toggleSupportModal,
} from "../../../entities/support.entity";

import SupportToggle from "./SupportToggle";
import SlideModal from "../SlideModal";
import FaqContent from "./FaqContent";
import {useRef} from "react";
import {useClickOutside} from "@reactuses/core";
import ContactUsContent from "./ContactUsContent";
import Modal from "../Modal";
import SuggestionContent from "./SuggestionContent";

const SupportWidget = () => {
  const {isOpen, openedModal} = supportEntity.use();

  const ref = useRef(null);
  useClickOutside(ref, () => {
    if (isOpen) toggleSupportButton();
  });

  return (
    <div
      ref={ref}
      className="hidden fixed md:flex flex-col items-end right-4 bottom-4 z-40"
    >
      {isOpen && <SupportMenu />}
      <SupportToggle />

      <SlideModal
        isOpen={openedModal === "find-answers"}
        close={() => toggleSupportModal(null)}
      >
        <FaqContent />
      </SlideModal>

      <SlideModal
        isOpen={openedModal === "contact-us"}
        close={() => toggleSupportModal(null)}
      >
        <ContactUsContent />
      </SlideModal>
      <Modal
        open={openedModal === "suggestion"}
        close={() => toggleSupportModal(null)}
      >
        <SuggestionContent />
      </Modal>
    </div>
  );
};

export default SupportWidget;
