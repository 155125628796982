/* eslint-disable import/named */
import { Suspense, useEffect } from "react";
import "./App.scss";
import AppRouter from "./appNavigation/AppRouter";
import GlobalLoader from "./components/atoms/GlobalLoader";
import ToastWidget from "./components/organisms/ToastWidget";
import PageLoader from "./components/organisms/PageLoader";
import { resetScreenZoom } from "./utils/resetScreenZoom";
import { I18nextProvider } from "react-i18next";
import i18n from "./translation/i18n";
import { languageEntity } from "./entities/translation.entity";
import { setBaseUrl } from "./entities/base.url.entity";
import { fireMessaging } from "./services/firebase";
import { isSupported, onMessage } from "firebase/messaging";
import { toast } from "./entities/app.entity";

function App() {
  const { language } = languageEntity.use();

  useEffect(() => {
    resetScreenZoom();
    i18n.changeLanguage(language);
  }, []);

  useEffect(() => {
    setBaseUrl(language);
  }, [language]);

  useEffect(() => {
    if (!fireMessaging || !isSupported) return;

    const messageListener = onMessage(fireMessaging, (payload) => {
      toast(payload?.notification?.title ?? "");
    });

    return () => {
      messageListener();
    };
  }, []);

  return (
    <>
      <I18nextProvider i18n={i18n}>
        <GlobalLoader />
        <ToastWidget />
        <Suspense fallback={<PageLoader />}>
          <AppRouter />
        </Suspense>
      </I18nextProvider>
    </>
  );
}

export default App;
