import React, { Dispatch, SetStateAction } from "react";
import Button from "../../../components/atoms/Button";
import Modal from "../../../components/molecules/Modal";
import { t } from "i18next";
import { setVisitedEditProduct } from "../../../entities/products.entity";

function ConfirmSwitchFromEditProduct({
  isOpen,
  onClose,
  clickedTab,
  setActiveTab,
}: {
  isOpen: boolean;
  onClose: () => void;
  clickedTab: number;
  setActiveTab: (activeTab: number) => void;
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t("Exit edit product")}>
      <div className="bg-white rounded-lg py-4">
        <p className="mt-2 mb-10">
          {t("Proceeding with this action won't save your changes.")}
        </p>
        <div className="flex gap-4 items-center justify-end mt-8">
          <Button isOutline label={t("Cancel")} onClick={() => onClose()} />
          <Button
            isDanger
            label={t("Proceed")}
            onClick={() => {
              onClose();
              setVisitedEditProduct(false);
              setActiveTab(clickedTab);
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmSwitchFromEditProduct;
