import clsx from "clsx";
import React, { DetailedHTMLProps } from "react";

type SelectPropType = DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  containerClassName?: string;
  placeholder?: string;
  colored?: boolean;
  options: Array<{ name: string; value: string | number } | string>;
  fieldSize?: "large" | "small";
};
const Select = ({
  containerClassName,
  options,
  placeholder,
  fieldSize,
  ...props
}: SelectPropType) => {
  const placeholderProps = { value: "", selected: true, disabled: true };

  return (
    <div
      className={clsx(
        "border flex items-center px-3 rounded-full mb-6 w-full",
        containerClassName
      )}
    >
      <select
        {...{ name: "", id: "", ...props }}
        className={clsx(
          "w-full text-sm font-medium text-gray-900 bg-transparent border-none outline-none",
          fieldSize === "small" ? "py-2" : "py-2.5",
          props.className
        )}
      >
        {placeholder && <option {...placeholderProps}>{placeholder}</option>}
        {options.map((option) => (
          <option
            className="text-sm"
            value={typeof option === "string" ? option : option.value}
            key={typeof option === "string" ? option : option.name}
          >
            {typeof option === "string" ? option : option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
