import React from "react";

const PageLoader = () => {
  return (
    <div>
      <div className={"h-screen w-screen flex items-center justify-center"}>
        <img src="/svgs/pif_logo.svg" alt="PIF" className="h-28 " />
      </div>
    </div>
  );
};

export default PageLoader;
