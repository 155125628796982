import { Link, useNavigate } from "react-router-dom";
import { authEntity } from "../entities/auth.entity";
import { useEffect } from "react";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const auth = authEntity.use();

  useEffect(() => {
    if (!auth) navigate("/auth/login", { replace: true });
  }, []);

  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <h1 className="text-9xl font-semibold mb-4 text-teal-500">404</h1>
      <h1 className="text-4xl text-teal-900 mb-10">Page not found!</h1>

      <Link to="/dashboard">
        <button className="bg-pif-teal text-teal-50 px-10 py-2 rounded">
          Go back to Dashboard
        </button>
      </Link>
    </div>
  );
};

export default NotFoundPage;
