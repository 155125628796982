/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestEnd,
  requestStart,
  toast,
  appEntity,
} from "../entities/app.entity";
import { authEntity, clearAuth } from "../entities/auth.entity";
import axios, { AxiosHeaders } from "axios";
import { useCallback, useEffect, useState } from "react";
import { removeEmptyKeys } from "../utils/helpers";
import { debounce } from "lodash";

// export const baseApi = "https://pif-staging-a6152c0342af.herokuapp.com/v1/en";

let currentAbortController: any = null;

type methodTypes = "get" | "post" | "put" | "patch" | "delete";
type ResponseType =
  | "arraybuffer"
  | "blob"
  | "document"
  | "json"
  | "text"
  | "stream";

export const fetchFromApi = ({
  path,
  method = "get",
  body,
  params,
  respType,
}: {
  path: string;
  method?: methodTypes;
  body?: any;
  params?: any;
  respType?: ResponseType;
}) => {
  const url = `${path}`;

  const requestConfig: {
    url: string;
    method: methodTypes;
    data?: any;
    headers?: any;
    params?: any;
  } = {
    url,
    method,
  };

  // const localAuthData = window.localStorage.getItem("pifApp-dash-auth");
  if (method !== "get" && body) requestConfig.data = body;
  if (params) requestConfig.params = params;
  // if (token) requestConfig.headers.Authorization = `Bearer ${token}`;

  // if (token) requestConfig.headers.Authorization = `Bearer ${token}`;
  const token: string = authEntity.get()?.token ?? "";
  const baseApi: string = appEntity.get()?.baseApi ?? "";

  // if (currentAbortController) currentAbortController.abort();

  currentAbortController = new AbortController();

  return axios({
    baseURL: baseApi,
    headers: {
      ...AxiosHeaders,
      Authorization: `Bearer ${token}`,
      ...requestConfig.headers,
    },
    // withCredentials: true,
    ...requestConfig,
    ...(respType && { responseType: respType }),
    signal: currentAbortController.signal,
  });
};

export const handleFetchError = (err: any) => {
  if (err.response?.data?.message) toast(err.response.data.message, "error");
  if (err.response?.status === 401) {
    clearAuth();
  }
};

export const uploadSingleImage = async (
  file: File,
  url: string,
  key: string
) => {
  const token: string = authEntity.get()?.token ?? "";
  const baseApi: string = appEntity.get()?.baseApi ?? "";

  const formData = new FormData();
  formData.append(key, file);
  try {
    requestStart("img-upload");
    const { data } = await axios.post(`${baseApi}/uploads/${url}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    if (data.message) toast(data.message);
    if (data.data) return data.data;
  } catch (error) {
    handleFetchError(error);
  } finally {
    requestEnd();
  }
};

export const uploadMultipleImages = async (
  files: File[],
  url: string,
  key: string,
  header?: any
) => {
  const token: string = authEntity.get()?.token ?? "";
  const baseApi: string = appEntity.get()?.baseApi ?? "";

  const formData = new FormData();
  files.forEach((file) => {
    formData.append(key, file);
  });

  try {
    requestStart("upload-image");

    const { data } = await axios.post(`${baseApi}/uploads/${url}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        ...header,
      },
    });

    if (data.message) {
      toast(data.message);
    }
  } catch (error) {
    handleFetchError(error);
  } finally {
    requestEnd();
  }
};

interface HookProps {
  path: string;
  method?: "get" | "post" | "put" | "patch" | "delete";
  body?: any;
  auth?: boolean;
  initialState?: any;
  page?: number;
  perPage?: number;
  queryParams?: { [key: string]: string | boolean | number };
  searchParams?: string;
}

export const useApi = ({
  path,
  method = "get",
  initialState = [],
  page = 1,
  perPage,
  queryParams = {},
  searchParams = "",
}: HookProps) => {
  const screenWidth = window.screen.width;
  const [requesting, setRequesting] = useState(false);
  const [data, setData] = useState<any>(initialState);
  const [err, setErr] = useState();
  const [meta, setMeta] = useState<any>({});
  const [kPage, setPage] = useState(page || 1);
  const [kPerPage, setPerPage] = useState(
    perPage || (screenWidth >= 640 ? 20 : 50)
  );
  const [kQueryParams, setQueryParams] = useState(queryParams);

  const [searchQuery, setSearchQuery] = useState(searchParams);

  const fetchData = useCallback(
    async (search?: string) => {
      setRequesting(true);
      try {
        const { data } = await fetchFromApi({
          path: path,
          method: method,
          params: removeEmptyKeys({
            page: meta.currentPage || kPage,
            per_page: kPerPage || perPage,
            search_query: search,
            ...kQueryParams,
          }),
        });
        if (data) {
          const { meta } = data;
          if (data.data) {
            setData(data.data);
          }
          if (meta && Object.keys(meta).length) setMeta(meta);
        }
      } catch (err: any) {
        setErr(err);
        handleFetchError(err);
      } finally {
        setRequesting(false);
      }
    },
    [kPage, kPerPage, path, kQueryParams]
  );

  useEffect(() => {
    fetchData(searchQuery);
  }, [kQueryParams, kPage, kPerPage, !searchQuery]);

  const handleChangeWithLib = useCallback(
    debounce(async (value) => {
      try {
        if (!value) return;
        await fetchData(value);
      } catch (error) {
        toast("An error occurred:", "error");
      }
    }, 500),
    []
  );

  useEffect(() => {
    handleChangeWithLib(searchQuery);
  }, [searchQuery]);

  return {
    requesting,
    path,
    data,
    err,
    reFetch: () => fetchData(),
    queryParams: {
      get: kQueryParams,
      set: (val: any) => setQueryParams((prev) => ({ ...prev, ...val })),
    },
    searchParams: {
      get: searchQuery,
      set: (val: any) => setSearchQuery(val),
    },
    paginate: {
      meta,
      page: kPage,
      perPage: kPerPage,
      setPerPage,
      handlePageChange: (value: number) => {
        setPage(value);
        setMeta({
          ...meta,
          currentPage: value,
        });
      },
    },
  };
};
