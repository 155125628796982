import { Icon } from "@iconify/react";
import { MouseEventHandler, useEffect, useState } from "react";
import heroiconNames from "../../data/heroicon-names";
import logoiconNames from "../../data/logoicon-names";

const iconSets = {
  Heroicons: heroiconNames,
  "Logo icons": logoiconNames,
};

const IconList = () => {
  const [selectedIcon, setSelectedIcon] = useState<
    (typeof heroiconNames)[number] | null
  >(null);
  const [activeIconSetName, setActiveIconSetName] =
    useState<keyof typeof iconSets>("Heroicons");
  const [isCopied, setIsCopied] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    if (selectedIcon) {
      timeoutId = setTimeout(() => {
        setSelectedIcon(null);
      }, 5000);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [selectedIcon]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [activeIconSetName]);

  const handleCopy: MouseEventHandler<HTMLButtonElement> = () => {
    if (selectedIcon) {
      navigator.clipboard.writeText(selectedIcon).then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1000);
      });
    }
  };

  const computedIconNames = searchInput.length
    ? iconSets[activeIconSetName].filter((icon) =>
        icon.includes(searchInput.toLowerCase().trim())
      )
    : iconSets[activeIconSetName];

  const otherIconSets: (keyof typeof iconSets)[] = Object.keys(iconSets).filter(
    (name) => name !== activeIconSetName
  ) as (keyof typeof iconSets)[];

  return (
    <div>
      <div className="px-6 py-2 flex items-center bg-white space-x-6 shadow mb-4 sticky top-0">
        <h1 className="text-center text-2xl text-darkest font-semibold font-montserrat sticky top-0 bg-white p-4">
          {activeIconSetName}
        </h1>
        {selectedIcon && (
          <div className="flex items-center space-x-2">
            <span>Selected ⇒</span>
            <div className="p-2 border rounded">
              <Icon icon={selectedIcon} className="w-5 h-5" />
            </div>
            <span className="p-2 border rounded bg-dark/5">{selectedIcon}</span>
            <button
              className="rounded px-1 h-10 flex items-center space-x-1"
              onClick={handleCopy}
            >
              <Icon
                icon={
                  isCopied
                    ? "heroicons:clipboard-document-check"
                    : "heroicons:square-2-stack"
                }
                className="h-6 w-6"
              />
              {isCopied && <span>copied! 👍🏽</span>}
            </button>
          </div>
        )}

        <span className="flex-grow" />

        <input
          type="search"
          className="border px-4 py-2 outline-none focus:border-dark/50 rounded-md w-96"
          placeholder="Search icons..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>

      <div className="grid [grid-template-columns:repeat(auto-fill,minmax(10rem,1fr))] gap-y-2 px-6 pt-5 pb-10 border-b">
        {computedIconNames.map((icon: string) => (
          <div
            key={`styleguide__icon-list-item_${icon}`}
            className="flex flex-col items-center justify-center p-2 hover:text-pif-teal text-dark"
            title={icon}
          >
            <div
              className="border rounded-md h-12 w-12 mb-2 flex items-center justify-center cursor-pointer"
              onClick={() => setSelectedIcon(icon)}
            >
              <Icon icon={icon} className="h-6 w-6" />
            </div>
            <span className="text-xs text-center select-none">
              {icon.replace(/^[^:]*:/, "")}
            </span>
          </div>
        ))}
      </div>

      {otherIconSets.map((iconSetName, index) => (
        <div
          key={`styleguide_other_icon_set_${index}`}
          className="border-b py-4 px-10 text-xl cursor-pointer"
          onClick={() => setActiveIconSetName(iconSetName)}
        >
          {iconSetName}
        </div>
      ))}

      <div className="px-10 py-6">
        <p className="text-lg font-medium text-dark">
          More icons can be found{" "}
          <a
            className="text-pif-teal"
            href="https://icones.js.org/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
        </p>
        <p className="text-sm mt-2">
          Make sure you seek the permission of your lead before using any icon
          not in this Styleguide
        </p>
      </div>
    </div>
  );
};

export default IconList;
