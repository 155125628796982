import { entity } from "simpler-state";

const defaultState: { baseUrl: string } = {
  baseUrl: "https://pif-staging-a6152c0342af.herokuapp.com/v1/en",
  // baseUrl: "https://pif-test.eu-north-1.elasticbeanstalk.com/v1/en",
};

export const baseUrlEntity = entity<{
  baseUrl: string;
}>(defaultState);

export const setBaseUrl = (language: string) =>
  baseUrlEntity.set((currentState) => ({
    ...currentState,
    baseUrl: `https://pif-staging-a6152c0342af.herokuapp.com/v1/${language}`,
  }));
