import { entity, persistence } from "simpler-state";
import { IUser, AuthStateType } from "../types/global.types";
import { appEntity } from "./app.entity";

const defaultAuth: AuthStateType = null;

const stored = window.localStorage.getItem("pifApp-dash-auth");

const initState: AuthStateType = stored ? JSON.parse(stored) : defaultAuth;

export const authEntity = entity<AuthStateType>(initState, [
  persistence("pifApp-dash-auth"),
]);

// actions
export const setAuth = (currentUser: IUser, token?: string) =>
  authEntity.set({ currentUser, token });

export const clearAuth = () => authEntity.set(null);

export const useAuth = () => {
  const auth = authEntity.use();
  const { currentMarketplace } = appEntity.use();

  return {
    isLoggedIn: auth?.token && auth?.currentUser,
    user: auth?.currentUser,
    userType: auth?.currentUser.userType,
    role: auth?.currentUser.role,
    isCustomRole: ![
      "platform-admin",
      "partner-admin",
      "brand-admin",
      "country-admin",
      "super-admin",
      "admin",
      "local-partner",
      "pos-user",
    ].includes(auth?.currentUser.role as string),
    userMarketplace: auth?.currentUser?.marketplaces?.includes(
      currentMarketplace
    )
      ? currentMarketplace
      : "",
    marketplaces: auth?.currentUser?.marketplaces,
    partnerId: auth?.currentUser.partnerId,
    hasProduct: auth?.currentUser?.hasProduct,
    brandId: auth?.currentUser.brandId,
    canWithdrawEarning: auth?.currentUser.canWithdrawEarning,
    hasNotificationId: auth?.currentUser.hasNotificationId,
  };
};
