import { ReactNode } from "react";

type EmptyStateProps = {
  heading: ReactNode | string;
  description: ReactNode | string;
  actions?: ReactNode;
};
const EmptyState = ({ heading, description, actions }: EmptyStateProps) => {
  return (
    <div className="p-20 space-y-5">
      <h1 className="font-montserrat text-4xl font-bold max-w-3xl leading-tight text-darkest">
        {heading}
      </h1>
      <p className="text-2xl text-dark text-opacity-80 leading-normal">
        {description}
      </p>
      {actions && actions}
    </div>
  );
};

export default EmptyState;
