import { entity, persistence } from "simpler-state";
import { fetchFromApi, handleFetchError } from "../services";
import { requestEnd, requestStart } from "./app.entity";

import {FaqInterface, LanguageObject} from "../../src/types/global.types";

export interface IFaq {
  question: LanguageObject;
  answer: LanguageObject;
  _id: string;
  close?: () => void;
}
const initState: FaqInterface[] = [];

export const faqEntity = entity<FaqInterface[]>(initState, [persistence("faqs")]);
export const setFaqs = (faq: FaqInterface[]) => faqEntity.set(faq);

export const fetchFaqs = async () => {
  const currentFaq = faqEntity.get();
  requestStart("fetch-faqs");
  try {
    const { data } = await fetchFromApi({
      path: "cms/faq",
    });
    
    if (JSON.stringify(currentFaq) !== JSON.stringify(data)) setFaqs(data.data);
  } catch (error) {
    handleFetchError(error);
  } finally {
    requestEnd();
  }
};
