import React, { FormEventHandler, useEffect, useState } from "react";
import { fetchFromApi, handleFetchError, useApi } from "../../../services";
import Button from "../../../components/atoms/Button";
import {
  appEntity,
  requestEnd,
  requestStart,
  toast,
} from "../../../entities/app.entity";
import { useNavigate } from "react-router-dom";
import { removeEmptyKeys, resolveLangText } from "../../../utils/helpers";
import { IProduct } from "../../../types/products.types";
import SearchInput from "../../../components/atoms/form/SearchInput";
import BrandProductCard from "../BrandSignup/BrandProductCard";
import { brandInvitationEntity } from "../../../entities/brand-invitation.enity";
import Multiselect from "multiselect-react-dropdown";
import { IBrandCategory } from "../../../types/global.types";
import RequiredAsterisk from "../../../components/atoms/form/RequiredAsterisk";
import { useTranslation } from "react-i18next";

const BrandProductInvitation = ({ brand }: { brand: any }) => {
  const { t } = useTranslation();
  const { invitationCode } = brandInvitationEntity.use();
  const navigate = useNavigate();
  const [selectedProducts, setSelectedProducts] = useState<Array<IProduct>>([]);
  const brandProducts = useApi({
    path: `brands/brand-products/public?brand_id=${brand._id}`,
  });
  const { requesting } = appEntity.use();
  const loading = requesting?.status && requesting.caller == "add-product";

  const [selectedCategories, setSelectedCategories] = useState<
    { key: string; id: string }[]
  >([]);

  const brandCategories = useApi({
    path: brand?._id
      ? `/brands/categories?brand_id=${brand?._id}&marketplace=${brand?.marketplaces?.[0]}`
      : "",
  });
  useEffect(() => {
    brandCategories.reFetch();
  }, [brand.data]);

  const categoryOptions = brandCategories.data?.brandCategories?.map(
    (el: IBrandCategory) => ({
      key: resolveLangText(el.name, "en"),
      id: el._id,
    })
  );
  const toggleSelectProduct = (el: any) => {
    const elId = el._id;
    const isSelected = selectedProducts.some((product) => product._id === elId);
    if (isSelected) {
      const updatedProducts = selectedProducts.filter(
        (product) => product._id !== elId
      );
      setSelectedProducts(updatedProducts);
    } else {
      setSelectedProducts([...selectedProducts, el]);
    }
  };
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    brandProducts.reFetch();
  }, [brand]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    requestStart("add-product");
    try {
      const { data } = await fetchFromApi({
        path: "products/pending-products",
        method: "post",
        body: removeEmptyKeys({
          code: invitationCode,
          brandProducts: selectedProducts.map((el) =>
            removeEmptyKeys({
              price: el.productType === "free-gift" ? 0 : parseInt(el.price),
              id: el._id,
              productType: el.productType,
              quantity: el.quantity,
              quantityAlert: el.quantityAlert,
              redemptionValidityPeriodType: el.redemptionValidityPeriodType,
              redemptionValidityType: el.redemptionValidityType,
              redemptionValidityValue:
                el.redemptionValidityType == "period" &&
                typeof el.redemptionValidityValue == "string"
                  ? parseInt(el.redemptionValidityValue)
                  : el.redemptionValidityValue,
              validThru: el.validThru,
              tax: el.tax,
            })
          ),
          brandSubcategoryIds: selectedCategories.map((el) => el.id),
        }),
      });
      if (data.message) {
        toast(data.message);
        navigate("/auth/login");
      }
    } catch (error) {
      handleFetchError(error);
    } finally {
      requestEnd();
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <h2 className="text-left mb-2">
        {t("Which brand category best describes your business")}{" "}
        <RequiredAsterisk />
      </h2>
      <Multiselect
        onSelect={setSelectedCategories}
        onRemove={setSelectedCategories}
        selectedValues={selectedCategories}
        options={categoryOptions}
        displayValue="key"
        style={{
          chips: { background: "#4DC2C1" },
        }}
      />
      <div className="my-5 text-left">
        <h1 className="text-xl font-semibold mb-2">{t("Add product")}</h1>
        <h2 className="mb-6">
          {t("Click the plus icon to add a product, set a price, and save.")}
        </h2>
        <SearchInput
          fieldSize="small"
          placeholder={t("Search")}
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            brandProducts.searchParams.set(e.target.value);
          }}
        />
      </div>
      <div className="h-full grid md:[grid-template-columns:repeat(auto-fill,minmax(23rem,1fr))] gap-6 overflow-y-auto pt-2 pb-4 px-1 ">
        {brandProducts.data.map((el: IProduct, index: number) => (
          <BrandProductCard
            key={`${index}`}
            product={el}
            selectedProducts={selectedProducts}
            onSelect={toggleSelectProduct}
          />
        ))}
      </div>
      <div className="col-span-2 flex justify-end mt-6 gap-4">
        <Button
          label={t("Submit")}
          loading={loading}
          style={{ background: brand?.brandColor }}
        />
      </div>
    </form>
  );
};

export default BrandProductInvitation;
