import clsx from "clsx";
import { t } from "i18next";
import React, { DetailedHTMLProps } from "react";

type SelectPropType = DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  containerClassName?: string;
  colored?: boolean;
  options: Array<{ name: string; value: string } | string>;
};
const SupportSelect = ({
  containerClassName,
  options,
  ...props
}: SelectPropType) => {
  return (
    <div className={clsx("border p-1 pr-2 rounded", containerClassName)}>
      <select
        className="border-none outline-none w-full py-1 text-sm font-medium text-gray-900"
        name=""
        id=""
        {...props}
      >
        {options.map((option) => (
          <option
            value=""
            key={typeof option === "string" ? option : option.name}
          >
            {typeof option === "string" ? t(option) : t(option.name)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SupportSelect;
