import clsx from "clsx";
import { ComponentProps, ReactElement } from "react";
import Ripple from "./Ripple";
import Spin from "./Spin";

type ButtonPropTypes = Omit<ComponentProps<"button">, "className"> & {
  bg?: string;
  buttonClassName?: string;
  buttonSize?: "large" | "small";
  containerClassName?: string;
  isDanger?: boolean;
  isOutline?: boolean;
  label?: string;
  labelClassName?: string;
  loading?: boolean;
  prefixIcon?: ReactElement;
  rippleColor?: string;
  suffixIcon?: ReactElement;
  wide?: boolean;
};

const Button = ({
  bg,
  buttonClassName,
  buttonSize,
  containerClassName,
  isDanger,
  isOutline,
  label,
  labelClassName,
  loading,
  prefixIcon,
  rippleColor = "bg-white",
  suffixIcon,
  wide,
  ...otherProps
}: ButtonPropTypes) => (
  <Ripple className={clsx("", containerClassName)} rippleColor={rippleColor}>
    <button
      {...otherProps}
      className={clsx(
        "rounded-full flex items-center justify-center text-sm gap-1.5 font-medium px-6 whitespace-nowrap",
        buttonSize === "small"
          ? "h-[2.375rem]"
          : buttonSize === "large"
          ? "h-[2.875rem]"
          : "h-[2.625rem]",
        isOutline
          ? "border bg-gray-100 active:!visibleborder-gray-300 transition-all duration-200 text-main-dark"
          : isDanger
          ? "bg-[#FFF0F3] text-[#FF0033]"
          : `${bg ? bg : "bg-pif-teal"} text-white`,
        wide && "w-full",
        buttonClassName
      )}
    >
      {prefixIcon && prefixIcon}
      {label && <span className={clsx(labelClassName)}>{label}</span>}
      {loading && <Spin />}
      {suffixIcon && suffixIcon}
    </button>
  </Ripple>
);

export default Button;
