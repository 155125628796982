import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/atoms/Button";
import AuthLayout from "../../../components/templates/AuthLayout";
import {
  appEntity,
  requestEnd,
  requestStart,
  toast,
} from "../../../entities/app.entity";
import { fetchFromApi, handleFetchError } from "../../../services";
import { setCode } from "../../../entities/brand-invitation.enity";
import { t } from "i18next";

const VerifyBrandInvitation = () => {
  const navigate = useNavigate();

  const { code } = useParams();
  const { requesting } = appEntity.use();
  const [data, setData] = useState(null);

  const isVerifying = requesting?.status && requesting.caller === "verify";
  const [verified, setVerified] = useState(false);

  const verify = async () => {
    requestStart("verify");
    try {
      const { data } = await fetchFromApi({
        path: `partners/verify-invite/${code}`,
        method: "get",
      });
      if (data.message) {
        toast(data.message);
        setVerified(true);
        setData(data);
        setCode(code);
        if (data.data.partnerExist) {
          if (data.data.hasProduct) {
            navigate(
              `/auth/accept-brand-invitation/${data.data.PifId}?has_product=true`
            );
          } else {
            navigate(`/auth/accept-brand-invitation/${data.data.PifId}`);
          }
        } else {
          if (data.data.hasProduct) {
            navigate(`/auth/brand/${data.data.PifId}`);
          } else {
            navigate(
              `/auth/football-club/${data.data.PifId}/${data.data.BrandSubcategory}`
            );
          }
        }
      }
    } catch (error) {
      handleFetchError(error);
      // navigate("/auth/login");
    } finally {
      requestEnd();
    }
  };

  useEffect(() => {
    verify();
  }, [code]);

  return (
    <AuthLayout>
      <div>
        <Button buttonSize="large" label={t("Verifying.....")} />
      </div>
    </AuthLayout>
  );
};

export default VerifyBrandInvitation;
