import { Icon } from "@iconify/react";
import { clsx } from "clsx";
import { DetailedHTMLProps, InputHTMLAttributes } from "react";

type InputPropType = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  containerClassName?: string;
  colored?: boolean;
  fieldSize?: "large" | "small";
};

const SearchInput = ({
  containerClassName,
  fieldSize,
  ...props
}: InputPropType) => {
  return (
    <div
      className={clsx(
        "relative w-full border flex items-center border-gray-300 focus-within:border-gray-400 mb-6 text-sm bg-white rounded-3xl",
        containerClassName
      )}
    >
      <Icon
        icon="heroicons:magnifying-glass"
        className={clsx(
          "text-main-black absolute",
          fieldSize === "large"
            ? "left-6"
            : fieldSize === "small"
            ? "left-3"
            : "left-4"
        )}
      />
      <input
        type="search"
        {...props}
        className={clsx(
          "w-full text-sm font-medium outline-none bg-transparent rounded border-none text-gray-900 focus:outline-none truncate",
          fieldSize === "large"
            ? "pl-10 py-4 pr-6"
            : fieldSize === "small"
            ? "pl-8 py-2 pr-3"
            : "pl-9 py-3 pr-4"
        )}
      />
    </div>
  );
};

export default SearchInput;
