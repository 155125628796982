import clsx from "clsx";
import {
  supportEntity,
  toggleSupportButton,
} from "../../../entities/support.entity";
import { Icon } from "@iconify/react";
import { t } from "i18next";

const SupportToggle = () => {
  const { isOpen } = supportEntity.use();

  const flipWidget = () => {
    toggleSupportButton();
  };
  return (
    <div
      onClick={flipWidget}
      className="rounded-3xl w-32 h-9 shadow-sm relative cursor-pointer text-white"
    >
      <button
        className={clsx(
          "rounded-3xl absolute inset-0 bg-darker-teal flex items-center justify-center transition-transform duration-500 ease-in-out [perspective:1000px] [backface-visibility:hidden] [-webkit-backface-visibility:hidden]",
          isOpen ? "[transform:rotateX(180deg)]" : "[transform:rotateX(0deg)]"
        )}
      >
        <Icon icon="heroicons:question-mark-circle" className="h-5 w-5 mr-1" />
        <span className="uppercase font-semibold ">{t("Support")}</span>
      </button>
      <button
        className={clsx(
          "rounded-3xl absolute inset-0 bg-darker-teal opacity-80 flex items-center justify-center transition-transform duration-500 ease-in-out [perspective:1000px] [backface-visibility:hidden] [-webkit-backface-visibility:hidden]",
          isOpen ? "[transform:rotateX(0deg)]" : "[transform:rotateX(180deg)]"
        )}
        style={{ zIndex: isOpen ? 1 : 0 }}
      >
        <Icon icon="heroicons:x-circle" className="h-5 w-5 mr-2" />
        <span className="uppercase font-semibold">{t("Close")}</span>
      </button>
    </div>
  );
};

export default SupportToggle;
