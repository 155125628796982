import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/atoms/Button";
import {
  appEntity,
  requestEnd,
  requestStart,
  toast,
} from "../../../entities/app.entity";
import { brandInvitationEntity } from "../../../entities/brand-invitation.enity";
import { fetchFromApi, handleFetchError } from "../../../services";

const FootballClubInvitation = ({ brand }: { brand: any }) => {
  const { invitationCode } = brandInvitationEntity.use();
  const navigate = useNavigate();

  const { requesting } = appEntity.use();

  const isLoading = requesting?.status && requesting.caller === "accept-invite";

  const handleSubmit = async () => {
    requestStart("accept-invite");
    try {
      const { data } = await fetchFromApi({
        path: "products/pending-products",
        method: "post",
        body: {
          code: invitationCode,
          //   brandSubcategoryIds: "",
        },
      });
      if (data.message) {
        toast(data.message);
        navigate("/auth/login");
      }
    } catch (error) {
      handleFetchError(error);
    } finally {
      requestEnd();
    }
  };

  return (
    <div className=" mb-5 w-full md:w-[34rem] rounded-3xl px-4 md:px-8 py-8 bg-white flex  text-dark flex-col">
      <img
        src={brand?.logoUrl}
        className="inline-block mx-auto mt-8 w-14 h-14"
        alt=""
      />
      <h2
        className="mt-4 mb-2 text-2xl font-semibold text-center text-main-black"
        style={{ color: brand?.brandColor }}
      >
        {t("Become a")}
        <span className="px-2">{brand?.name}</span>
        {t("vendor")}
      </h2>
      <h5 className="mb-10 text-center ">
        {t("Becoming a brand partner on PIF is as simple as 1..2..3!")}
      </h5>
      <p className="mb-10 text-center ">
        {t("Are you sure you want to join")} {brand?.name}{" "}
        {t("on PIF as a sponsor partner?")}
      </p>
      <div className="grid grid-cols-2 gap-4">
        <Button
          onClick={() => navigate("/auth/login")}
          label={t("Reject")}
          buttonClassName="!bg-[#FFF0F3] !text-[#FF0033]"
          wide
        />
        <Button
          label={t("Accept")}
          loading={isLoading}
          onClick={() => handleSubmit()}
          wide
        />
      </div>
    </div>
  );
};

export default FootballClubInvitation;
