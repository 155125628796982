import { entity } from "simpler-state";
import { supportStateType } from "../types/global.types";

const defaultState = {
  isOpen: false,
  openedModal: null,
};

export const supportEntity = entity<supportStateType>(defaultState);

// actions
export const toggleSupportButton = () =>
  supportEntity.set((currentState) => ({
    ...currentState,
    isOpen: !currentState.isOpen,
  }));

export const toggleSupportModal = (modal: supportStateType["openedModal"]) =>
  supportEntity.set((currentState) => {
    const { openedModal } = currentState;

    if (!modal || openedModal === modal) currentState.openedModal = null;
    if (modal) currentState.isOpen = false;

    currentState.openedModal = modal;

    return { ...currentState };
  });
