import { Icon } from "@iconify/react";
import { t } from "i18next";
import React, { useState } from "react";
import tw from "tw-tailwind";
import Button from "../../../components/atoms/Button";
import Checkbox from "../../../components/atoms/form/Checkbox/Checkbox";
import Input from "../../../components/atoms/form/Input";
import RequiredAsterisk from "../../../components/atoms/form/RequiredAsterisk";
import Select from "../../../components/atoms/form/Select";
import Modal from "../../../components/molecules/Modal";
import { getCurrencyByCountryCode } from "../../../entities/platform.entity";
import { useBrandSignupProductValidation } from "../../../hooks/useSignupProductValidation";
import { ISignupProductForm } from "../../../types/global.types";
import { IProduct } from "../../../types/products.types";
import { resolveLangText } from "../../../utils/helpers";

const BrandProductCard = ({
  selectedProducts,
  product,
  onSelect,
}: {
  selectedProducts: Array<IProduct>;
  product: IProduct;
  onSelect: (e: any) => void;
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [isTaxFree, setIsTaxFree] = useState(false);
  const initialForm = {
    name: { en: "" },
    price: "",
    images: [],
    description: { en: "" },
    categories: [],
    quantity: 10,
    redemptionValidityPeriodType: "weeks",
    productType: "regular-product",
    validThru: "",
    quantityAlert: "",
    redemptionValidityType: "period",
    redemptionValidityValue: "",
    isRated18: false,
    tax: 0,
  };
  const productExist: any = selectedProducts.find((p) => p._id === product._id);
  const [form, setForm] = useState<ISignupProductForm>(
    productExist !== undefined ? { ...productExist } : initialForm
  );
  const [quantityLimitType, setQuantityLimitType] = useState(
    form.quantity == -1 ? "Unlimited" : "Limited"
  );
  const { validateForm, formErrors } = useBrandSignupProductValidation(
    quantityLimitType,
    isTaxFree
  );
  return (
    <div className="relative flex flex-col h-auto overflow-hidden border cursor-pointer rounded-2xl hover:shadow-cardShadow">
      <div className="w-full h-56 rounded-t-2xl ">
        <img
          className="block object-cover w-full h-full rounded-t-2xl "
          src={product.photo}
          loading="lazy"
        />
      </div>
      <div className="px-4 py-4 ">
        <p className="mb-2 text-left ">{resolveLangText(product.name, "en")}</p>
        <div className="flex justify-between">
          <p>
            {getCurrencyByCountryCode(product.marketplace || "")}{" "}
            {form.productType == "free-gift" ? 0 : form.price}
          </p>
          <p
            onClick={() => {
              setOpenModal(true);
            }}
          >
            {t("Edit")}
          </p>
        </div>
      </div>
      <div
        className="absolute top-2 right-2 w-8 h-8 rounded-full bg-white flex items-center justify-center cursor-pointer border border-[#d9e2e2] hover:shadow-lg"
        onClick={() => {
          selectedProducts.some((p) => p._id === product._id)
            ? onSelect(product)
            : setOpenModal(true);
        }}
      >
        <Icon
          icon={
            selectedProducts.some((p) => p._id === product._id)
              ? "heroicons:minus-20-solid"
              : "heroicons:plus"
          }
        />
      </div>
      <Modal
        maxWidth="max-w-[36rem]"
        title={t("Add a brand product")}
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <div>
          <img
            className="block object-cover h-64 mx-auto w-72 rounded-t-2xl my-14 "
            src={product.photo}
            loading="lazy"
          />
          <h1 className="my-2">{t("Product Settings")}</h1>
          <div className="grid grid-cols-2 gap-x-6">
            <FieldGroup>
              <Label>{t("Product type")}</Label>
              <Select
                containerClassName="mb-4"
                value={form.productType}
                options={[
                  { name: t("Free gift"), value: "free-gift" },
                  { name: t("Regular product"), value: "regular-product" },
                ]}
                onChange={(e) =>
                  setForm({
                    ...form,
                    productType: e.target.value,
                  })
                }
              />
            </FieldGroup>
            {form.productType == "regular-product" ? (
              <FieldGroup>
                <Label>{t("Product price")}</Label>
                <Input
                  error={formErrors.price}
                  value={form.price}
                  type="number"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      price: e.target.value,
                    })
                  }
                />
              </FieldGroup>
            ) : null}

            <FieldGroup>
              <Label>
                {t("Valid thru")}
                <RequiredAsterisk />
              </Label>
              <Input
                error={formErrors.validThru}
                value={form.validThru}
                fieldSize="small"
                type={"date"}
                onChange={(e) =>
                  setForm({
                    ...form,
                    validThru: e.target.value,
                  })
                }
              />
            </FieldGroup>
            <FieldGroup>
              <Label>{t("Quantity limit")}</Label>
              <Select
                placeholder={t("Select quantity type")}
                value={quantityLimitType}
                onChange={(e) => setQuantityLimitType(e.target.value)}
                options={["Unlimited", "Limited"]}
              />
            </FieldGroup>
            {quantityLimitType == "Limited" && (
              <>
                <FieldGroup>
                  <Label>{t("Quantity")} </Label>
                  <Input
                    type="number"
                    error={formErrors.quantity}
                    value={form.quantity}
                    fieldSize="small"
                    placeholder="number in stock"
                    onChange={(e) =>
                      setForm({ ...form, quantity: parseInt(e.target.value) })
                    }
                  />
                </FieldGroup>
                <FieldGroup>
                  <Label>{t("Quantity limit alert")}</Label>
                  <Input
                    type="number"
                    error={formErrors.quantityAlert}
                    value={form.quantityAlert}
                    fieldSize="small"
                    onChange={(e) =>
                      setForm({
                        ...form,
                        quantityAlert: parseInt(e.target.value),
                      })
                    }
                  />
                </FieldGroup>
              </>
            )}
            <FieldGroup>
              <Label>{t("Redemption validity type")}</Label>
              <div className="mb-4">
                <Select
                  options={[
                    // "Date",
                    "Period",
                  ].map((el) => ({
                    name: t(el),
                    value: el.toLowerCase(),
                  }))}
                  value={form?.redemptionValidityType}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      redemptionValidityType: e.target.value,
                    })
                  }
                />
              </div>
            </FieldGroup>
            {form.redemptionValidityType === "date" ? (
              <FieldGroup>
                <Label>{t("Redemption validity date")}</Label>
                <Input
                  error={formErrors.redemptionValidityValue}
                  fieldSize="small"
                  type={"date"}
                  value={form.redemptionValidityValue}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      redemptionValidityValue: e.target.value,
                    })
                  }
                />
              </FieldGroup>
            ) : form.redemptionValidityType === "period" ? (
              <FieldGroup>
                <Label>{t("Redemption validity period")}</Label>
                <div className="grid grid-cols-[1fr_2fr] gap-3">
                  <Input
                    value={form.redemptionValidityValue}
                    type={"number"}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        redemptionValidityValue: parseInt(e.target.value),
                      })
                    }
                    fieldSize="small"
                    placeholder={`No. of ${form.redemptionValidityPeriodType}`}
                  />
                  <Select
                    placeholder={t("Select period type")}
                    value={form.redemptionValidityPeriodType}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        redemptionValidityPeriodType: e.target.value,
                      })
                    }
                    options={["Days", "Weeks", "Months"].map((el) => ({
                      name: t(el),
                      value: el.toLowerCase(),
                    }))}
                  />
                </div>
              </FieldGroup>
            ) : null}
            <div>
              <Checkbox
                label={t("Tax free")}
                checked={isTaxFree}
                onChange={() => setIsTaxFree(!isTaxFree)}
              />

              {!isTaxFree ? (
                <FieldGroup className="mt-2">
                  <Label>{t("Tax")}</Label>
                  <Input
                    max={100}
                    value={form.tax}
                    type="number"
                    fieldSize="small"
                    onChange={(e) => {
                      if (
                        e.target.value !== "" &&
                        parseFloat(e.target.value) < 0
                      ) {
                        setForm({
                          ...form,
                          tax: 0,
                        });
                      } else {
                        setForm({
                          ...form,
                          tax: parseInt(e.target.value),
                        });
                      }
                    }}
                  />
                </FieldGroup>
              ) : null}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-6 mt-14">
            <Button
              wide
              isOutline
              label={t("Cancel")}
              onClick={() => setOpenModal(false)}
            />
            <Button
              wide
              label={t("Add")}
              onClick={() => {
                if (validateForm(form)) {
                  onSelect({
                    ...product,
                    ...form,
                    quantity:
                      quantityLimitType == "Unlimited" ? -1 : form.quantity,
                  });
                  setOpenModal(false);
                }
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BrandProductCard;

const Label = tw.label`text-sm text-dark block mb-4 whitespace-nowrap`;
const FieldGroup = tw.div`text-sm  w-full`;
