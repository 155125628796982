import { clsx } from "clsx";
import { DetailedHTMLProps, TextareaHTMLAttributes } from "react";

type TextareaPropType = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  containerClassName?: string;
  textareaClassName?: string;
  colored?: boolean;
  error?: string | boolean;
  fieldSize?: "large" | "small";
};

const Textarea = ({
  containerClassName,
  textareaClassName,
  fieldSize,
  error,
  ...props
}: TextareaPropType) => {
  return (
    <div
      className={clsx(
        "w-full border relative focus-within:!border-gray-400 mb-6 rounded-3xl",
        error ? "!border-accent-red" : "!border-gray-300",
        containerClassName
      )}
    >
      <textarea
        {...props}
        className={clsx(
          "block w-full text-sm font-medium  outline-none bg-transparent rounded-[inherit] h-full border-none text-gray-900 p-3",
          {
            "!px-6 !py-4": fieldSize === "large",
            "!py-2 !px-3": fieldSize === "small",
          },
          textareaClassName
        )}
      />
      {error && typeof error == "string" ? (
        <div className="absolute left-0 text-xs leading-none translate-y-1 text-accent-pink top-full w-full truncate">
          {error}
        </div>
      ) : null}
    </div>
  );
};

export default Textarea;
