import {
  supportEntity,
  toggleSupportModal,
} from "../../../entities/support.entity";
import Input from "../../atoms/form/Input";
import Textarea from "../../atoms/form/Textarea";
import Button from "../../atoms/Button";
import SupportSelect from "../../atoms/form/SupportSelect";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

const ContactUsContent = () => {
  const { openedModal } = supportEntity.use();
  const issueOptions = [
    "General Inquiries",
    "Missing Transaction",
    "Products",
    "Undelivered Items",
    "Others",
  ];
  const { t } = useTranslation();

  return (
    <div className={"bg-white shadow rounded-lg flex flex-col justify-between"}>
      <h1 className="py-3 px-4 bg-darker-teal rounded-t-lg text-white text-lg font-semibold flex items-center justify-between">
        <span>{t("Leave us a message")}</span>

        <button onClick={() => toggleSupportModal(null)}>
          <Icon
            icon={
              openedModal === "contact-us-modal"
                ? "heroicons:x-mark"
                : "heroicons:minus"
            }
          />
        </button>
      </h1>
      <div className="flex-grow overflow-y-auto p-4">
        <p className="mb-2 text-darkest">{t("What do you need help with?")}</p>
        <div className="space-y-3">
          <div className="space-y-1">
            <h1 className="font-medium text-heading-2 text-darker-teal mt-0">
              {t("Email address")}
            </h1>
            <Input fieldSize="small" />
          </div>
          <div className="space-y-1">
            <h1 className="font-medium text-heading-2 text-darker-teal mt-0">
              {t("Subject")}
            </h1>
            <Input fieldSize="small" />
          </div>
          <div className="space-y-1">
            <h1 className="font-medium text-heading-2 text-darker-teal mt-0">
              {t("Description")}
            </h1>
            <p className="text-sm text-darkest">
              {t(
                "Please enter your request details our support staff will reach out as soon as possible."
              )}
            </p>
            <Textarea rows={4} />
          </div>
          <div className="space-y-1">
            <h1 className="font-medium text-heading-2 text-darker-teal mt-0">
              {t("Issue Category")}
            </h1>
            <p className="text-sm text-darkest">
              {t("What are you reaching out about?")}
            </p>
            <SupportSelect options={issueOptions} />
          </div>
          <div className="space-y-1">
            <h1 className="font-medium text-heading-2 text-darker-teal mt-0">
              {t("Attachments")}
            </h1>
            <Input type="file" />
          </div>
        </div>
      </div>

      <div className="py-3 px-4 flex justify-end">
        <Button label={t("Send")} onClick={() => toggleSupportModal(null)} />
      </div>
    </div>
  );
};

export default ContactUsContent;
