const logoiconNames = [
  "logos:100tb",
  "logos:500px",
  "logos:6px",
  "logos:active-campaign",
  "logos:active-campaign-icon",
  "logos:admob",
  "logos:adobe-after-effects",
  "logos:adobe-animate",
  "logos:adobe-dreamweaver",
  "logos:adobe-illustrator",
  "logos:adobe-incopy",
  "logos:adobe-indesign",
  "logos:adobe-lightroom",
  "logos:adobe-photoshop",
  "logos:adobe-premiere",
  "logos:adobe-xd",
  "logos:adonisjs",
  "logos:adonisjs-icon",
  "logos:adroll",
  "logos:adyen",
  "logos:aerogear",
  "logos:aerospike",
  "logos:aerospike-icon",
  "logos:aha",
  "logos:ai",
  "logos:airbnb",
  "logos:airbnb-icon",
  "logos:airbrake",
  "logos:airflow",
  "logos:airflow-icon",
  "logos:airtable",
  "logos:aix",
  "logos:akamai",
  "logos:akka",
  "logos:alfresco",
  "logos:algolia",
  "logos:alpinejs",
  "logos:alpinejs-icon",
  "logos:altair",
  "logos:amazon-chime",
  "logos:amazon-connect",
  "logos:amd",
  "logos:amex",
  "logos:amp",
  "logos:amp-icon",
  "logos:ampersand",
  "logos:amplication",
  "logos:amplication-icon",
  "logos:amplitude",
  "logos:amplitude-icon",
  "logos:android",
  "logos:android-icon",
  "logos:android-vertical",
  "logos:angellist",
  "logos:angular",
  "logos:angular-icon",
  "logos:ansible",
  "logos:ant-design",
  "logos:apache",
  "logos:apache-camel",
  "logos:apache-cloudstack",
  "logos:api-ai",
  "logos:apiary",
  "logos:apigee",
  "logos:apitools",
  "logos:apollostack",
  "logos:apostrophe",
  "logos:appbase",
  "logos:appbaseio",
  "logos:appbaseio-icon",
  "logos:appcelerator",
  "logos:appcenter",
  "logos:appcenter-icon",
  "logos:appcircle",
  "logos:appcircle-icon",
  "logos:appcode",
  "logos:appdynamics",
  "logos:appdynamics-icon",
  "logos:appfog",
  "logos:apphub",
  "logos:appium",
  "logos:apple",
  "logos:apple-app-store",
  "logos:apple-pay",
  "logos:appmaker",
  "logos:apportable",
  "logos:appsignal",
  "logos:appsignal-icon",
  "logos:apptentive",
  "logos:appveyor",
  "logos:appwrite",
  "logos:appwrite-icon",
  "logos:arangodb",
  "logos:arangodb-icon",
  "logos:architect",
  "logos:architect-icon",
  "logos:archlinux",
  "logos:arduino",
  "logos:argo",
  "logos:argo-icon",
  "logos:arm",
  "logos:armory",
  "logos:armory-icon",
  "logos:asana",
  "logos:asana-icon",
  "logos:asciidoctor",
  "logos:assembla",
  "logos:assembla-icon",
  "logos:astro",
  "logos:astro-icon",
  "logos:astronomer",
  "logos:atlassian",
  "logos:atom",
  "logos:atom-icon",
  "logos:atomic",
  "logos:atomic-icon",
  "logos:atomicojs",
  "logos:atomicojs-icon",
  "logos:aurelia",
  "logos:aurora",
  "logos:aurous",
  "logos:auth0",
  "logos:auth0-icon",
  "logos:authy",
  "logos:autocode",
  "logos:autoit",
  "logos:autoprefixer",
  "logos:ava",
  "logos:awesome",
  "logos:aws",
  "logos:aws-amplify",
  "logos:aws-api-gateway",
  "logos:aws-app-mesh",
  "logos:aws-appflow",
  "logos:aws-appsync",
  "logos:aws-athena",
  "logos:aws-aurora",
  "logos:aws-backup",
  "logos:aws-batch",
  "logos:aws-certificate-manager",
  "logos:aws-cloudformation",
  "logos:aws-cloudfront",
  "logos:aws-cloudsearch",
  "logos:aws-cloudtrail",
  "logos:aws-cloudwatch",
  "logos:aws-codebuild",
  "logos:aws-codecommit",
  "logos:aws-codedeploy",
  "logos:aws-codepipeline",
  "logos:aws-codestar",
  "logos:aws-cognito",
  "logos:aws-config",
  "logos:aws-documentdb",
  "logos:aws-dynamodb",
  "logos:aws-ec2",
  "logos:aws-ecs",
  "logos:aws-eks",
  "logos:aws-elastic-beanstalk",
  "logos:aws-elastic-cache",
  "logos:aws-elasticache",
  "logos:aws-elb",
  "logos:aws-eventbridge",
  "logos:aws-fargate",
  "logos:aws-glacier",
  "logos:aws-glue",
  "logos:aws-iam",
  "logos:aws-keyspaces",
  "logos:aws-kinesis",
  "logos:aws-kms",
  "logos:aws-lake-formation",
  "logos:aws-lambda",
  "logos:aws-lightsail",
  "logos:aws-mobilehub",
  "logos:aws-mq",
  "logos:aws-msk",
  "logos:aws-neptune",
  "logos:aws-open-search",
  "logos:aws-opsworks",
  "logos:aws-quicksight",
  "logos:aws-rds",
  "logos:aws-redshift",
  "logos:aws-route53",
  "logos:aws-s3",
  "logos:aws-secrets-manager",
  "logos:aws-ses",
  "logos:aws-shield",
  "logos:aws-sns",
  "logos:aws-sqs",
  "logos:aws-step-functions",
  "logos:aws-systems-manager",
  "logos:aws-timestream",
  "logos:aws-vpc",
  "logos:aws-waf",
  "logos:aws-xray",
  "logos:axios",
  "logos:azure",
  "logos:azure-icon",
  "logos:babel",
  "logos:backbone",
  "logos:backbone-icon",
  "logos:backerkit",
  "logos:baker-street",
  "logos:balena",
  "logos:bamboo",
  "logos:base",
  "logos:basecamp",
  "logos:basecamp-icon",
  "logos:basekit",
  "logos:bash",
  "logos:bash-icon",
  "logos:batch",
  "logos:beats",
  "logos:behance",
  "logos:bem",
  "logos:bem-2",
  "logos:bigpanda",
  "logos:bing",
  "logos:bitballoon",
  "logos:bitbar",
  "logos:bitbucket",
  "logos:bitcoin",
  "logos:bitnami",
  "logos:bitrise",
  "logos:bitrise-icon",
  "logos:blender",
  "logos:blitzjs",
  "logos:blitzjs-icon",
  "logos:blocs",
  "logos:blogger",
  "logos:blossom",
  "logos:bluemix",
  "logos:blueprint",
  "logos:bluetooth",
  "logos:booqable",
  "logos:booqable-icon",
  "logos:bootstrap",
  "logos:bosun",
  "logos:botanalytics",
  "logos:bourbon",
  "logos:bower",
  "logos:bowtie",
  "logos:box",
  "logos:brackets",
  "logos:brainjs",
  "logos:branch",
  "logos:branch-icon",
  "logos:brandfolder",
  "logos:brandfolder-icon",
  "logos:brave",
  "logos:braze",
  "logos:braze-icon",
  "logos:broadcom",
  "logos:broadcom-icon",
  "logos:broccoli",
  "logos:brotli",
  "logos:browserify",
  "logos:browserify-icon",
  "logos:browserling",
  "logos:browserslist",
  "logos:browserstack",
  "logos:browsersync",
  "logos:brunch",
  "logos:bubble",
  "logos:bubble-icon",
  "logos:buck",
  "logos:buddy",
  "logos:buffer",
  "logos:bugherd",
  "logos:bugherd-icon",
  "logos:bugsee",
  "logos:bugsnag",
  "logos:bugsnag-icon",
  "logos:builder-io",
  "logos:builder-io-icon",
  "logos:buildkite",
  "logos:buildkite-icon",
  "logos:bulma",
  "logos:bun",
  "logos:bunny-net",
  "logos:bunny-net-icon",
  "logos:c",
  "logos:c-plusplus",
  "logos:c-sharp",
  "logos:cachet",
  "logos:caffe2",
  "logos:cakephp",
  "logos:cakephp-icon",
  "logos:calibre",
  "logos:calibre-icon",
  "logos:campaignmonitor",
  "logos:campaignmonitor-icon",
  "logos:campfire",
  "logos:canjs",
  "logos:capacitorjs",
  "logos:capacitorjs-icon",
  "logos:capistrano",
  "logos:carbide",
  "logos:cardano",
  "logos:cardano-icon",
  "logos:cassandra",
  "logos:celluloid",
  "logos:centos",
  "logos:centos-icon",
  "logos:certbot",
  "logos:ceylon",
  "logos:chai",
  "logos:chalk",
  "logos:changetip",
  "logos:chargebee",
  "logos:chargebee-icon",
  "logos:chartblocks",
  "logos:chef",
  "logos:chevereto",
  "logos:chromatic",
  "logos:chromatic-icon",
  "logos:chrome",
  "logos:chrome-web-store",
  "logos:cinder",
  "logos:circleci",
  "logos:cirrus",
  "logos:cirrus-ci",
  "logos:clickdeploy",
  "logos:clio-lang",
  "logos:clion",
  "logos:cljs",
  "logos:clojure",
  "logos:close",
  "logos:cloud9",
  "logos:cloudacademy",
  "logos:cloudacademy-icon",
  "logos:cloudant",
  "logos:cloudcraft",
  "logos:cloudera",
  "logos:cloudflare",
  "logos:cloudflare-workers",
  "logos:cloudflare-workers-icon",
  "logos:cloudinary",
  "logos:cloudinary-icon",
  "logos:cloudlinux",
  "logos:clusterhq",
  "logos:cobalt",
  "logos:cockpit",
  "logos:cocoapods",
  "logos:coda",
  "logos:coda-icon",
  "logos:codacy",
  "logos:codebase",
  "logos:codebeat",
  "logos:codecademy",
  "logos:codeception",
  "logos:codeclimate",
  "logos:codeclimate-icon",
  "logos:codecov",
  "logos:codecov-icon",
  "logos:codefactor",
  "logos:codefactor-icon",
  "logos:codefund",
  "logos:codefund-icon",
  "logos:codeigniter",
  "logos:codeigniter-icon",
  "logos:codepen",
  "logos:codepen-icon",
  "logos:codepicnic",
  "logos:codepush",
  "logos:codersrank",
  "logos:codersrank-icon",
  "logos:coderwall",
  "logos:codesandbox",
  "logos:codesandbox-icon",
  "logos:codeschool",
  "logos:codesee",
  "logos:codesee-icon",
  "logos:codeship",
  "logos:codio",
  "logos:codrops",
  "logos:coffeescript",
  "logos:commitizen",
  "logos:compass",
  "logos:component",
  "logos:componentkit",
  "logos:compose",
  "logos:compose-multiplatform",
  "logos:composer",
  "logos:conan-io",
  "logos:concourse",
  "logos:concrete5",
  "logos:concretecms",
  "logos:concretecms-icon",
  "logos:conda",
  "logos:confluence",
  "logos:consul",
  "logos:containership",
  "logos:contentful",
  "logos:convox",
  "logos:convox-icon",
  "logos:copyleft",
  "logos:copyleft-pirate",
  "logos:corda",
  "logos:cordova",
  "logos:coreos",
  "logos:coreos-icon",
  "logos:couchbase",
  "logos:couchdb",
  "logos:couchdb-icon",
  "logos:coursera",
  "logos:coveralls",
  "logos:coverity",
  "logos:cpanel",
  "logos:craft",
  "logos:craftcms",
  "logos:crashlytics",
  "logos:crateio",
  "logos:create-react-app",
  "logos:createjs",
  "logos:crittercism",
  "logos:cross-browser-testing",
  "logos:crossbrowsertesting",
  "logos:crowdprocess",
  "logos:crucible",
  "logos:crystal",
  "logos:css-3",
  "logos:css-3-official",
  "logos:cssnext",
  "logos:cucumber",
  "logos:curl",
  "logos:customerio",
  "logos:customerio-icon",
  "logos:cyclejs",
  "logos:cypress",
  "logos:cypress-icon",
  "logos:d3",
  "logos:dapulse",
  "logos:dart",
  "logos:dashlane",
  "logos:dashlane-icon",
  "logos:dat",
  "logos:database-labs",
  "logos:datadog",
  "logos:datagrip",
  "logos:dataspell",
  "logos:datocms",
  "logos:datocms-icon",
  "logos:dbt",
  "logos:dbt-icon",
  "logos:dcos",
  "logos:dcos-icon",
  "logos:debian",
  "logos:delicious",
  "logos:delicious-burger",
  "logos:delighted",
  "logos:delighted-icon",
  "logos:deno",
  "logos:dependabot",
  "logos:dependencyci",
  "logos:deploy",
  "logos:deployhq",
  "logos:deployhq-icon",
  "logos:deppbot",
  "logos:derby",
  "logos:descript",
  "logos:descript-icon",
  "logos:designernews",
  "logos:desk",
  "logos:dev",
  "logos:dev-icon",
  "logos:deviantart",
  "logos:deviantart-icon",
  "logos:dgraph",
  "logos:dgraph-icon",
  "logos:dialogflow",
  "logos:digital-ocean",
  "logos:dimer",
  "logos:dinersclub",
  "logos:discord",
  "logos:discord-icon",
  "logos:discourse",
  "logos:discourse-icon",
  "logos:discover",
  "logos:disqus",
  "logos:distelli",
  "logos:divshot",
  "logos:django",
  "logos:django-icon",
  "logos:dockbit",
  "logos:docker",
  "logos:docker-icon",
  "logos:doctrine",
  "logos:docusaurus",
  "logos:dojo",
  "logos:dojo-icon",
  "logos:dojo-toolkit",
  "logos:dotcloud",
  "logos:dotnet",
  "logos:doubleclick",
  "logos:dovetail",
  "logos:dovetail-icon",
  "logos:dreamfactory",
  "logos:dreamhost",
  "logos:dribbble",
  "logos:dribbble-icon",
  "logos:drift",
  "logos:drip",
  "logos:drizzle",
  "logos:drizzle-icon",
  "logos:drone",
  "logos:drone-icon",
  "logos:drools",
  "logos:drools-icon",
  "logos:dropbox",
  "logos:dropmark",
  "logos:dropzone",
  "logos:drupal",
  "logos:drupal-icon",
  "logos:duckduckgo",
  "logos:dynatrace",
  "logos:dynatrace-icon",
  "logos:dyndns",
  "logos:eager",
  "logos:ebanx",
  "logos:eclipse",
  "logos:eclipse-icon",
  "logos:ecma",
  "logos:edgio",
  "logos:edgio-icon",
  "logos:editorconfig",
  "logos:egghead",
  "logos:elasticbox",
  "logos:elasticpath",
  "logos:elasticpath-icon",
  "logos:elasticsearch",
  "logos:electron",
  "logos:element",
  "logos:elemental-ui",
  "logos:elementary",
  "logos:eleventy",
  "logos:ello",
  "logos:elm",
  "logos:elm-classic",
  "logos:elo",
  "logos:emacs",
  "logos:emacs-classic",
  "logos:embedly",
  "logos:ember",
  "logos:ember-tomster",
  "logos:emmet",
  "logos:enact",
  "logos:engine-yard",
  "logos:engine-yard-icon",
  "logos:envato",
  "logos:envoy",
  "logos:envoy-icon",
  "logos:envoyer",
  "logos:envoyproxy",
  "logos:enyo",
  "logos:epsagon",
  "logos:epsagon-icon",
  "logos:erlang",
  "logos:es6",
  "logos:esbuild",
  "logos:esdoc",
  "logos:eslint",
  "logos:eslint-old",
  "logos:eta",
  "logos:eta-icon",
  "logos:etcd",
  "logos:ethereum",
  "logos:ethereum-color",
  "logos:ethers",
  "logos:ethnio",
  "logos:eventbrite",
  "logos:eventbrite-icon",
  "logos:eventsentry",
  "logos:evergreen",
  "logos:evergreen-icon",
  "logos:expo",
  "logos:expo-icon",
  "logos:exponent",
  "logos:express",
  "logos:fabric",
  "logos:fabric-io",
  "logos:facebook",
  "logos:falcor",
  "logos:famous",
  "logos:fastify",
  "logos:fastify-icon",
  "logos:fastlane",
  "logos:fastly",
  "logos:fauna",
  "logos:fauna-icon",
  "logos:feathersjs",
  "logos:fedora",
  "logos:fetch",
  "logos:ffmpeg",
  "logos:ffmpeg-icon",
  "logos:figma",
  "logos:firebase",
  "logos:firefox",
  "logos:flannel",
  "logos:flarum",
  "logos:flask",
  "logos:flat-ui",
  "logos:flattr",
  "logos:flattr-icon",
  "logos:fleep",
  "logos:flexible-gs",
  "logos:flickr",
  "logos:flickr-icon",
  "logos:flight",
  "logos:flocker",
  "logos:floodio",
  "logos:flow",
  "logos:flowxo",
  "logos:floydhub",
  "logos:flutter",
  "logos:flux",
  "logos:fluxxor",
  "logos:fly",
  "logos:fly-icon",
  "logos:flyjs",
  "logos:fogbugz",
  "logos:fogbugz-icon",
  "logos:fomo",
  "logos:fomo-icon",
  "logos:font-awesome",
  "logos:forest",
  "logos:forestadmin",
  "logos:forestadmin-icon",
  "logos:forever",
  "logos:formkeep",
  "logos:fortran",
  "logos:foundation",
  "logos:framed",
  "logos:framer",
  "logos:framework7",
  "logos:framework7-icon",
  "logos:freebsd",
  "logos:freedcamp",
  "logos:freedcamp-icon",
  "logos:freedomdefined",
  "logos:fresh",
  "logos:frontapp",
  "logos:fsharp",
  "logos:fuchsia",
  "logos:galliumos",
  "logos:game-analytics",
  "logos:game-analytics-icon",
  "logos:ganache",
  "logos:ganache-icon",
  "logos:gatsby",
  "logos:gaugeio",
  "logos:geekbot",
  "logos:geetest",
  "logos:geetest-icon",
  "logos:get-satisfaction",
  "logos:getyourguide",
  "logos:ghost",
  "logos:giantswarm",
  "logos:gin",
  "logos:git",
  "logos:git-icon",
  "logos:gitboard",
  "logos:github",
  "logos:github-actions",
  "logos:github-copilot",
  "logos:github-icon",
  "logos:github-octocat",
  "logos:gitkraken",
  "logos:gitlab",
  "logos:gitter",
  "logos:gitup",
  "logos:glamorous",
  "logos:glamorous-icon",
  "logos:gleam",
  "logos:glimmerjs",
  "logos:glint",
  "logos:glitch",
  "logos:glitch-icon",
  "logos:gnome",
  "logos:gnome-icon",
  "logos:gnu",
  "logos:gnu-net",
  "logos:gnupg",
  "logos:gnupg-icon",
  "logos:go",
  "logos:gocd",
  "logos:godot",
  "logos:godot-icon",
  "logos:gohorse",
  "logos:goland",
  "logos:gomix",
  "logos:google",
  "logos:google-2014",
  "logos:google-360suite",
  "logos:google-admob",
  "logos:google-ads",
  "logos:google-adsense",
  "logos:google-adwords",
  "logos:google-analytics",
  "logos:google-calendar",
  "logos:google-cloud",
  "logos:google-cloud-functions",
  "logos:google-cloud-platform",
  "logos:google-cloud-run",
  "logos:google-currents",
  "logos:google-data-studio",
  "logos:google-developers",
  "logos:google-developers-icon",
  "logos:google-domains",
  "logos:google-domains-icon",
  "logos:google-drive",
  "logos:google-fit",
  "logos:google-gmail",
  "logos:google-gsuite",
  "logos:google-home",
  "logos:google-icon",
  "logos:google-inbox",
  "logos:google-keep",
  "logos:google-maps",
  "logos:google-marketing-platform",
  "logos:google-meet",
  "logos:google-one",
  "logos:google-optimize",
  "logos:google-pay",
  "logos:google-pay-icon",
  "logos:google-photos",
  "logos:google-play",
  "logos:google-play-icon",
  "logos:google-plus",
  "logos:google-search-console",
  "logos:google-tag-manager",
  "logos:google-wallet",
  "logos:google-workspace",
  "logos:gopher",
  "logos:gordon",
  "logos:gradle",
  "logos:grafana",
  "logos:grails",
  "logos:grammarly",
  "logos:grammarly-icon",
  "logos:grape",
  "logos:graphcool",
  "logos:graphene",
  "logos:graphql",
  "logos:gratipay",
  "logos:grav",
  "logos:gravatar",
  "logos:gravatar-icon",
  "logos:graylog",
  "logos:graylog-icon",
  "logos:greensock",
  "logos:greensock-icon",
  "logos:gridsome",
  "logos:gridsome-icon",
  "logos:grommet",
  "logos:groovehq",
  "logos:grove",
  "logos:growth-book",
  "logos:growth-book-icon",
  "logos:grpc",
  "logos:grunt",
  "logos:gulp",
  "logos:gunicorn",
  "logos:gunjs",
  "logos:gusto",
  "logos:gwt",
  "logos:hack",
  "logos:hacker-one",
  "logos:hadoop",
  "logos:haiku",
  "logos:haiku-icon",
  "logos:haml",
  "logos:hanami",
  "logos:handlebars",
  "logos:hapi",
  "logos:hardhat",
  "logos:hardhat-icon",
  "logos:harness",
  "logos:harness-icon",
  "logos:harrow",
  "logos:hashicorp",
  "logos:hashicorp-icon",
  "logos:hashnode",
  "logos:hashnode-icon",
  "logos:haskell",
  "logos:haskell-icon",
  "logos:hasura",
  "logos:hasura-icon",
  "logos:haxe",
  "logos:haxl",
  "logos:hbase",
  "logos:hcaptcha",
  "logos:hcaptcha-icon",
  "logos:headlessui",
  "logos:headlessui-icon",
  "logos:heap",
  "logos:heap-icon",
  "logos:helm",
  "logos:helpscout",
  "logos:helpscout-icon",
  "logos:hermes",
  "logos:heroku",
  "logos:heroku-icon",
  "logos:heroku-redis",
  "logos:heron",
  "logos:hexo",
  "logos:hhvm",
  "logos:hibernate",
  "logos:highcharts",
  "logos:hipchat",
  "logos:hipercard",
  "logos:hoa",
  "logos:homebrew",
  "logos:hoodie",
  "logos:hookstate",
  "logos:hootsuite",
  "logos:hootsuite-icon",
  "logos:horizon",
  "logos:hosted-graphite",
  "logos:hostgator",
  "logos:hostgator-icon",
  "logos:hotjar",
  "logos:hotjar-icon",
  "logos:houndci",
  "logos:html-5",
  "logos:html5-boilerplate",
  "logos:httpie",
  "logos:httpie-icon",
  "logos:hubspot",
  "logos:huggy",
  "logos:hugo",
  "logos:humongous",
  "logos:hyper",
  "logos:hyperapp",
  "logos:ibm",
  "logos:ieee",
  "logos:ietf",
  "logos:ifttt",
  "logos:imagemin",
  "logos:imba",
  "logos:imba-icon",
  "logos:immer",
  "logos:immer-icon",
  "logos:immutable",
  "logos:impala",
  "logos:importio",
  "logos:infer",
  "logos:inferno",
  "logos:influxdb",
  "logos:influxdb-icon",
  "logos:ink",
  "logos:insomnia",
  "logos:instagram",
  "logos:instagram-icon",
  "logos:intel",
  "logos:intellij-idea",
  "logos:intercom",
  "logos:intercom-icon",
  "logos:internetexplorer",
  "logos:invision",
  "logos:invision-icon",
  "logos:io",
  "logos:ionic",
  "logos:ionic-icon",
  "logos:ios",
  "logos:iron",
  "logos:iron-icon",
  "logos:itsalive",
  "logos:itsalive-icon",
  "logos:jade",
  "logos:jamstack",
  "logos:jamstack-icon",
  "logos:jasmine",
  "logos:java",
  "logos:javascript",
  "logos:jcb",
  "logos:jekyll",
  "logos:jelastic",
  "logos:jelastic-icon",
  "logos:jenkins",
  "logos:jest",
  "logos:jetbrains",
  "logos:jetbrains-icon",
  "logos:jetbrains-space",
  "logos:jetbrains-space-icon",
  "logos:jfrog",
  "logos:jhipster",
  "logos:jhipster-icon",
  "logos:jira",
  "logos:joomla",
  "logos:jotai",
  "logos:jquery",
  "logos:jquery-mobile",
  "logos:jruby",
  "logos:jsbin",
  "logos:jscs",
  "logos:jsdelivr",
  "logos:jsdom",
  "logos:jsfiddle",
  "logos:json",
  "logos:jspm",
  "logos:jss",
  "logos:juju",
  "logos:julia",
  "logos:jupyter",
  "logos:jwt",
  "logos:jwt-icon",
  "logos:kafka",
  "logos:kafka-icon",
  "logos:kaios",
  "logos:kallithea",
  "logos:karma",
  "logos:kde",
  "logos:keen",
  "logos:kemal",
  "logos:keycdn",
  "logos:keycdn-icon",
  "logos:keymetrics",
  "logos:keystonejs",
  "logos:khan-academy",
  "logos:khan-academy-icon",
  "logos:kibana",
  "logos:kickstarter",
  "logos:kickstarter-icon",
  "logos:kinto",
  "logos:kinto-icon",
  "logos:kinvey",
  "logos:kirby",
  "logos:kirby-icon",
  "logos:kissmetrics",
  "logos:kissmetrics-monochromatic",
  "logos:kitematic",
  "logos:kloudless",
  "logos:knex",
  "logos:knockout",
  "logos:koa",
  "logos:kong",
  "logos:kong-icon",
  "logos:kontena",
  "logos:kops",
  "logos:kore",
  "logos:koreio",
  "logos:kotlin",
  "logos:kotlin-icon",
  "logos:kraken",
  "logos:krakenjs",
  "logos:ktor",
  "logos:ktor-icon",
  "logos:kubernetes",
  "logos:kustomer",
  "logos:languagetool",
  "logos:laravel",
  "logos:lastfm",
  "logos:lateral",
  "logos:lateral-icon",
  "logos:launchdarkly",
  "logos:launchdarkly-icon",
  "logos:launchkit",
  "logos:launchrock",
  "logos:leaflet",
  "logos:leankit",
  "logos:leankit-icon",
  "logos:lerna",
  "logos:less",
  "logos:lets-cloud",
  "logos:letsencrypt",
  "logos:leveldb",
  "logos:librato",
  "logos:liftweb",
  "logos:lighthouse",
  "logos:lightstep",
  "logos:lightstep-icon",
  "logos:lighttpd",
  "logos:linkedin",
  "logos:linkedin-icon",
  "logos:linkerd",
  "logos:linode",
  "logos:linux-mint",
  "logos:linux-tux",
  "logos:lit",
  "logos:lit-icon",
  "logos:litmus",
  "logos:loader",
  "logos:locent",
  "logos:lodash",
  "logos:logentries",
  "logos:loggly",
  "logos:logmatic",
  "logos:logstash",
  "logos:lookback",
  "logos:looker",
  "logos:looker-icon",
  "logos:loom",
  "logos:loom-icon",
  "logos:loopback",
  "logos:loopback-icon",
  "logos:losant",
  "logos:lotus",
  "logos:lua",
  "logos:lucene",
  "logos:lucene-net",
  "logos:lumen",
  "logos:lynda",
  "logos:macos",
  "logos:macosx",
  "logos:madge",
  "logos:maestro",
  "logos:mageia",
  "logos:magento",
  "logos:magneto",
  "logos:mailchimp",
  "logos:mailchimp-freddie",
  "logos:maildeveloper",
  "logos:mailgun",
  "logos:mailgun-icon",
  "logos:mailjet",
  "logos:mailjet-icon",
  "logos:malinajs",
  "logos:mandrill",
  "logos:mandrill-shield",
  "logos:manifoldjs",
  "logos:manjaro",
  "logos:mantine",
  "logos:mantine-icon",
  "logos:mantl",
  "logos:manuscript",
  "logos:mapbox",
  "logos:mapbox-icon",
  "logos:maps-me",
  "logos:mapzen",
  "logos:mapzen-icon",
  "logos:mariadb",
  "logos:mariadb-icon",
  "logos:marionette",
  "logos:markdown",
  "logos:marko",
  "logos:marvel",
  "logos:mastercard",
  "logos:mastodon",
  "logos:mastodon-icon",
  "logos:material-ui",
  "logos:materializecss",
  "logos:matomo",
  "logos:matomo-icon",
  "logos:matplotlib",
  "logos:matplotlib-icon",
  "logos:matter",
  "logos:matter-icon",
  "logos:mattermost",
  "logos:mattermost-icon",
  "logos:maven",
  "logos:maxcdn",
  "logos:mdn",
  "logos:mdx",
  "logos:meanio",
  "logos:medium",
  "logos:medium-icon",
  "logos:medusa",
  "logos:medusa-icon",
  "logos:memcached",
  "logos:memsql",
  "logos:memsql-icon",
  "logos:mention",
  "logos:mercurial",
  "logos:mern",
  "logos:mesos",
  "logos:mesosphere",
  "logos:messenger",
  "logos:metabase",
  "logos:metamask",
  "logos:metamask-icon",
  "logos:meteor",
  "logos:meteor-icon",
  "logos:micro",
  "logos:micro-icon",
  "logos:micro-python",
  "logos:microcosm",
  "logos:micron",
  "logos:micron-icon",
  "logos:microsoft",
  "logos:microsoft-azure",
  "logos:microsoft-edge",
  "logos:microsoft-icon",
  "logos:microsoft-onedrive",
  "logos:microsoft-power-bi",
  "logos:microsoft-teams",
  "logos:microsoft-windows",
  "logos:mida",
  "logos:mida-icon",
  "logos:middleman",
  "logos:milligram",
  "logos:mint-lang",
  "logos:mio",
  "logos:mist",
  "logos:mithril",
  "logos:mixmax",
  "logos:mixpanel",
  "logos:mlab",
  "logos:mobx",
  "logos:mocha",
  "logos:mockflow",
  "logos:mockflow-icon",
  "logos:modernizr",
  "logos:modulus",
  "logos:modx",
  "logos:modx-icon",
  "logos:moltin",
  "logos:moltin-icon",
  "logos:momentjs",
  "logos:monday",
  "logos:monday-icon",
  "logos:monero",
  "logos:mongodb",
  "logos:mongodb-icon",
  "logos:mongolab",
  "logos:mono",
  "logos:moon",
  "logos:mootools",
  "logos:morpheus",
  "logos:morpheus-icon",
  "logos:mozilla",
  "logos:mparticle",
  "logos:mparticle-icon",
  "logos:mps",
  "logos:mps-icon",
  "logos:msw",
  "logos:msw-icon",
  "logos:multipass",
  "logos:mysql",
  "logos:mysql-icon",
  "logos:myth",
  "logos:naiveui",
  "logos:namecheap",
  "logos:nanonets",
  "logos:nasm",
  "logos:nativescript",
  "logos:nats",
  "logos:nats-icon",
  "logos:neat",
  "logos:neo4j",
  "logos:neonmetrics",
  "logos:neovim",
  "logos:nestjs",
  "logos:net",
  "logos:netbeans",
  "logos:netflix",
  "logos:netflix-icon",
  "logos:netlify",
  "logos:netlify-icon",
  "logos:netuitive",
  "logos:neverinstall",
  "logos:neverinstall-icon",
  "logos:new-relic",
  "logos:new-relic-icon",
  "logos:nextjs",
  "logos:nextjs-icon",
  "logos:nginx",
  "logos:nhost",
  "logos:nhost-icon",
  "logos:nightwatch",
  "logos:nim-lang",
  "logos:nocodb",
  "logos:nodal",
  "logos:node-sass",
  "logos:nodebots",
  "logos:nodejitsu",
  "logos:nodejs",
  "logos:nodejs-icon",
  "logos:nodemon",
  "logos:nodeos",
  "logos:nodewebkit",
  "logos:nomad",
  "logos:nomad-icon",
  "logos:notion",
  "logos:notion-icon",
  "logos:now",
  "logos:noysi",
  "logos:npm",
  "logos:npm-2",
  "logos:npm-icon",
  "logos:nuclide",
  "logos:numpy",
  "logos:nuodb",
  "logos:nuxt",
  "logos:nuxt-icon",
  "logos:nvidia",
  "logos:nx",
  "logos:oauth",
  "logos:observablehq",
  "logos:ocaml",
  "logos:octodns",
  "logos:octopus-deploy",
  "logos:olapic",
  "logos:olark",
  "logos:onesignal",
  "logos:opbeat",
  "logos:open-graph",
  "logos:open-zeppelin",
  "logos:open-zeppelin-icon",
  "logos:openai",
  "logos:openai-icon",
  "logos:openapi",
  "logos:openapi-icon",
  "logos:opencart",
  "logos:opencollective",
  "logos:opencv",
  "logos:openframeworks",
  "logos:opengl",
  "logos:openjs-foundation",
  "logos:openjs-foundation-icon",
  "logos:openlayers",
  "logos:openshift",
  "logos:opensource",
  "logos:openstack",
  "logos:openstack-icon",
  "logos:opentelemetry",
  "logos:opentelemetry-icon",
  "logos:opera",
  "logos:opsee",
  "logos:opsgenie",
  "logos:opsmatic",
  "logos:optimizely",
  "logos:optimizely-icon",
  "logos:oracle",
  "logos:oreilly",
  "logos:origami",
  "logos:origin",
  "logos:oshw",
  "logos:osquery",
  "logos:otto",
  "logos:overloop",
  "logos:overloop-icon",
  "logos:p5js",
  "logos:packer",
  "logos:pagekit",
  "logos:pagekite",
  "logos:pagerduty",
  "logos:pagerduty-icon",
  "logos:panda",
  "logos:parcel",
  "logos:parcel-icon",
  "logos:parse",
  "logos:parsehub",
  "logos:partytown",
  "logos:partytown-icon",
  "logos:passbolt",
  "logos:passbolt-icon",
  "logos:passport",
  "logos:patreon",
  "logos:payload",
  "logos:paypal",
  "logos:peer5",
  "logos:pepperoni",
  "logos:percona",
  "logos:percy",
  "logos:percy-icon",
  "logos:perf-rocks",
  "logos:periscope",
  "logos:perl",
  "logos:phalcon",
  "logos:phoenix",
  "logos:phonegap",
  "logos:phonegap-bot",
  "logos:php",
  "logos:php-alt",
  "logos:phpstorm",
  "logos:picasa",
  "logos:pingdom",
  "logos:pingy",
  "logos:pinterest",
  "logos:pipedrive",
  "logos:pipefy",
  "logos:pivotal-tracker",
  "logos:pixate",
  "logos:pixelapse",
  "logos:pixijs",
  "logos:pkg",
  "logos:planetscale",
  "logos:planless",
  "logos:planless-icon",
  "logos:plasmic",
  "logos:plastic-scm",
  "logos:platformio",
  "logos:play",
  "logos:playwright",
  "logos:pluralsight",
  "logos:pluralsight-icon",
  "logos:pm2",
  "logos:pm2-icon",
  "logos:pnpm",
  "logos:pocket-base",
  "logos:podio",
  "logos:poeditor",
  "logos:polymer",
  "logos:positionly",
  "logos:postcss",
  "logos:postgraphile",
  "logos:postgresql",
  "logos:postman",
  "logos:postman-icon",
  "logos:pouchdb",
  "logos:preact",
  "logos:precursor",
  "logos:prerender",
  "logos:prerender-icon",
  "logos:prestashop",
  "logos:presto",
  "logos:prettier",
  "logos:prisma",
  "logos:prismic",
  "logos:prismic-icon",
  "logos:processing",
  "logos:processwire",
  "logos:processwire-icon",
  "logos:productboard",
  "logos:productboard-icon",
  "logos:producteev",
  "logos:producthunt",
  "logos:progress",
  "logos:prometheus",
  "logos:promises",
  "logos:proofy",
  "logos:prospect",
  "logos:protoio",
  "logos:protonet",
  "logos:protractor",
  "logos:prott",
  "logos:pug",
  "logos:pumpkindb",
  "logos:puppet",
  "logos:puppet-icon",
  "logos:puppeteer",
  "logos:puppy-linux",
  "logos:purescript",
  "logos:purescript-icon",
  "logos:pushbullet",
  "logos:pusher",
  "logos:pusher-icon",
  "logos:pwa",
  "logos:pycharm",
  "logos:pypi",
  "logos:pyscript",
  "logos:python",
  "logos:pytorch",
  "logos:pytorch-icon",
  "logos:pyup",
  "logos:q",
  "logos:qlik",
  "logos:qordoba",
  "logos:qt",
  "logos:qualcomm",
  "logos:quarkus",
  "logos:quarkus-icon",
  "logos:quay",
  "logos:quobyte",
  "logos:quora",
  "logos:qwik",
  "logos:r-lang",
  "logos:rabbitmq",
  "logos:rabbitmq-icon",
  "logos:rackspace",
  "logos:rackspace-icon",
  "logos:rails",
  "logos:ramda",
  "logos:raml",
  "logos:rancher",
  "logos:rancher-icon",
  "logos:randomcolor",
  "logos:raphael",
  "logos:raspberry-pi",
  "logos:rax",
  "logos:react",
  "logos:react-query",
  "logos:react-query-icon",
  "logos:react-router",
  "logos:react-spring",
  "logos:react-styleguidist",
  "logos:reactivex",
  "logos:realm",
  "logos:reapp",
  "logos:reasonml",
  "logos:reasonml-icon",
  "logos:recaptcha",
  "logos:recoil",
  "logos:recoil-icon",
  "logos:reddit",
  "logos:reddit-icon",
  "logos:redhat",
  "logos:redhat-icon",
  "logos:redis",
  "logos:redsmin",
  "logos:redspread",
  "logos:redux",
  "logos:redux-observable",
  "logos:redux-saga",
  "logos:redwoodjs",
  "logos:refactor",
  "logos:reindex",
  "logos:relay",
  "logos:release",
  "logos:remergr",
  "logos:remix",
  "logos:remix-icon",
  "logos:renovatebot",
  "logos:replit",
  "logos:replit-icon",
  "logos:require",
  "logos:rescript",
  "logos:rescript-icon",
  "logos:rest",
  "logos:rest-li",
  "logos:rethinkdb",
  "logos:retool",
  "logos:retool-icon",
  "logos:riak",
  "logos:rider",
  "logos:riot",
  "logos:risingwave",
  "logos:risingwave-icon",
  "logos:rkt",
  "logos:rocket-chat",
  "logos:rocket-chat-icon",
  "logos:rocksdb",
  "logos:rocky-linux",
  "logos:rocky-linux-icon",
  "logos:rollbar",
  "logos:rollbar-icon",
  "logos:rollupjs",
  "logos:rome",
  "logos:rome-icon",
  "logos:ros",
  "logos:rsa",
  "logos:rsmq",
  "logos:rubocop",
  "logos:ruby",
  "logos:rubygems",
  "logos:rubymine",
  "logos:rum",
  "logos:run-above",
  "logos:runnable",
  "logos:runscope",
  "logos:rust",
  "logos:rxdb",
  "logos:safari",
  "logos:sagui",
  "logos:sails",
  "logos:salesforce",
  "logos:saltstack",
  "logos:sameroom",
  "logos:samsung",
  "logos:sanity",
  "logos:sap",
  "logos:sass",
  "logos:sass-doc",
  "logos:saucelabs",
  "logos:scala",
  "logos:scaledrone",
  "logos:scaphold",
  "logos:scribd",
  "logos:scribd-icon",
  "logos:seaborn",
  "logos:seaborn-icon",
  "logos:section",
  "logos:section-icon",
  "logos:sectionio",
  "logos:segment",
  "logos:segment-icon",
  "logos:selenium",
  "logos:semantic-release",
  "logos:semantic-ui",
  "logos:semantic-web",
  "logos:semaphore",
  "logos:semaphoreci",
  "logos:sencha",
  "logos:sendgrid",
  "logos:sendgrid-icon",
  "logos:seneca",
  "logos:sensu",
  "logos:sensu-icon",
  "logos:sentry",
  "logos:sentry-icon",
  "logos:sequelize",
  "logos:serveless",
  "logos:serverless",
  "logos:sherlock",
  "logos:sherlock-icon",
  "logos:shields",
  "logos:shipit",
  "logos:shippable",
  "logos:shogun",
  "logos:shopify",
  "logos:sidekick",
  "logos:sidekiq",
  "logos:sidekiq-icon",
  "logos:signal",
  "logos:sinatra",
  "logos:singlestore",
  "logos:singlestore-icon",
  "logos:siphon",
  "logos:sitepoint",
  "logos:sk-hynix",
  "logos:skaffolder",
  "logos:sketch",
  "logos:sketchapp",
  "logos:skylight",
  "logos:skype",
  "logos:slack",
  "logos:slack-icon",
  "logos:slides",
  "logos:slim",
  "logos:smartling",
  "logos:smashingmagazine",
  "logos:snap-svg",
  "logos:snowflake",
  "logos:snowflake-icon",
  "logos:snowpack",
  "logos:snupps",
  "logos:snyk",
  "logos:socket-io",
  "logos:solarwinds",
  "logos:solid",
  "logos:solidity",
  "logos:solidjs",
  "logos:solidjs-icon",
  "logos:solr",
  "logos:sonarqube",
  "logos:soundcloud",
  "logos:sourcegraph",
  "logos:sourcetrail",
  "logos:sourcetree",
  "logos:spark",
  "logos:sparkcentral",
  "logos:sparkpost",
  "logos:speakerdeck",
  "logos:speedcurve",
  "logos:spidermonkey",
  "logos:spidermonkey-icon",
  "logos:spinnaker",
  "logos:splunk",
  "logos:spotify",
  "logos:spotify-icon",
  "logos:spree",
  "logos:spring",
  "logos:spring-icon",
  "logos:sqldep",
  "logos:sqlite",
  "logos:square",
  "logos:squarespace",
  "logos:stackbit",
  "logos:stackbit-icon",
  "logos:stackblitz",
  "logos:stackblitz-icon",
  "logos:stackoverflow",
  "logos:stackoverflow-icon",
  "logos:stackshare",
  "logos:stacksmith",
  "logos:stash",
  "logos:stately",
  "logos:stately-icon",
  "logos:statuspage",
  "logos:stdlib",
  "logos:stdlib-icon",
  "logos:steam",
  "logos:steemit",
  "logos:stenciljs",
  "logos:stenciljs-icon",
  "logos:stepsize",
  "logos:stepsize-icon",
  "logos:steroids",
  "logos:stetho",
  "logos:stickermule",
  "logos:stigg",
  "logos:stigg-icon",
  "logos:stimulus",
  "logos:stimulus-icon",
  "logos:stitch",
  "logos:stoplight",
  "logos:stormpath",
  "logos:storyblocks",
  "logos:storyblocks-icon",
  "logos:storyblok",
  "logos:storyblok-icon",
  "logos:storybook",
  "logos:storybook-icon",
  "logos:strapi",
  "logos:strapi-icon",
  "logos:streamlit",
  "logos:strider",
  "logos:stripe",
  "logos:strongloop",
  "logos:struts",
  "logos:styleci",
  "logos:stylefmt",
  "logos:stylelint",
  "logos:stylis",
  "logos:stylus",
  "logos:sublimetext",
  "logos:sublimetext-icon",
  "logos:subversion",
  "logos:sugarss",
  "logos:supabase",
  "logos:supabase-icon",
  "logos:supergiant",
  "logos:supersonic",
  "logos:supportkit",
  "logos:surge",
  "logos:surrealdb",
  "logos:surrealdb-icon",
  "logos:survicate",
  "logos:survicate-icon",
  "logos:suse",
  "logos:susy",
  "logos:svelte",
  "logos:svelte-icon",
  "logos:svelte-kit",
  "logos:svg",
  "logos:svgator",
  "logos:swagger",
  "logos:swc",
  "logos:swift",
  "logos:swiftype",
  "logos:swimm",
  "logos:swr",
  "logos:symfony",
  "logos:sysdig",
  "logos:sysdig-icon",
  "logos:t3",
  "logos:tableau",
  "logos:tableau-icon",
  "logos:taiga",
  "logos:tailwindcss",
  "logos:tailwindcss-icon",
  "logos:tapcart",
  "logos:tapcart-icon",
  "logos:targetprocess",
  "logos:taskade",
  "logos:taskade-icon",
  "logos:tastejs",
  "logos:tauri",
  "logos:tealium",
  "logos:teamcity",
  "logos:teamgrid",
  "logos:teamwork",
  "logos:teamwork-icon",
  "logos:tectonic",
  "logos:telegram",
  "logos:tensorflow",
  "logos:terminal",
  "logos:terraform",
  "logos:terraform-icon",
  "logos:terser",
  "logos:terser-icon",
  "logos:testcafe",
  "logos:testing-library",
  "logos:testlodge",
  "logos:testmunk",
  "logos:thimble",
  "logos:threejs",
  "logos:thymeleaf",
  "logos:thymeleaf-icon",
  "logos:tidal",
  "logos:tidal-icon",
  "logos:tiktok",
  "logos:tiktok-icon",
  "logos:titon",
  "logos:tnw",
  "logos:todoist",
  "logos:todoist-icon",
  "logos:todomvc",
  "logos:tomcat",
  "logos:toml",
  "logos:tor",
  "logos:torus",
  "logos:traackr",
  "logos:trac",
  "logos:trace",
  "logos:travis-ci",
  "logos:travis-ci-monochrome",
  "logos:treasuredata",
  "logos:treasuredata-icon",
  "logos:treehouse",
  "logos:trello",
  "logos:trpc",
  "logos:truffle",
  "logos:truffle-icon",
  "logos:tsmc",
  "logos:tsnode",
  "logos:tsu",
  "logos:tsuru",
  "logos:tumblr",
  "logos:tumblr-icon",
  "logos:tunein",
  "logos:tuple",
  "logos:turbopack",
  "logos:turbopack-icon",
  "logos:turborepo",
  "logos:turborepo-icon",
  "logos:turret",
  "logos:tutsplus",
  "logos:tutum",
  "logos:twilio",
  "logos:twilio-icon",
  "logos:twitch",
  "logos:twitter",
  "logos:typeform",
  "logos:typeform-icon",
  "logos:typeorm",
  "logos:typescript",
  "logos:typescript-icon",
  "logos:typescript-icon-round",
  "logos:typo3",
  "logos:typo3-icon",
  "logos:ubuntu",
  "logos:udacity",
  "logos:udacity-icon",
  "logos:udemy",
  "logos:udemy-icon",
  "logos:uikit",
  "logos:umu",
  "logos:unbounce",
  "logos:unbounce-icon",
  "logos:undertow",
  "logos:unionpay",
  "logos:unitjs",
  "logos:unito",
  "logos:unito-icon",
  "logos:unity",
  "logos:unocss",
  "logos:unrealengine",
  "logos:unrealengine-icon",
  "logos:upcase",
  "logos:upstash",
  "logos:upstash-icon",
  "logos:upwork",
  "logos:user-testing",
  "logos:user-testing-icon",
  "logos:uservoice",
  "logos:uservoice-icon",
  "logos:uwsgi",
  "logos:v8",
  "logos:v8-ignition",
  "logos:v8-turbofan",
  "logos:vaadin",
  "logos:vaddy",
  "logos:vagrant",
  "logos:vagrant-icon",
  "logos:vault",
  "logos:vault-icon",
  "logos:vector",
  "logos:vector-timber",
  "logos:vercel",
  "logos:vercel-icon",
  "logos:verdaccio",
  "logos:verdaccio-icon",
  "logos:vernemq",
  "logos:victorops",
  "logos:vim",
  "logos:vimeo",
  "logos:vimeo-icon",
  "logos:vine",
  "logos:visa",
  "logos:visaelectron",
  "logos:visual-studio",
  "logos:visual-studio-code",
  "logos:visual-website-optimizer",
  "logos:vitejs",
  "logos:vitest",
  "logos:vivaldi",
  "logos:vivaldi-icon",
  "logos:vlang",
  "logos:void",
  "logos:vue",
  "logos:vuetifyjs",
  "logos:vueuse",
  "logos:vulkan",
  "logos:vultr",
  "logos:vultr-icon",
  "logos:vwo",
  "logos:w3c",
  "logos:waffle",
  "logos:waffle-icon",
  "logos:wagtail",
  "logos:wakatime",
  "logos:walkme",
  "logos:watchman",
  "logos:wayscript",
  "logos:wayscript-icon",
  "logos:wearos",
  "logos:weave",
  "logos:web-dev",
  "logos:web-dev-icon",
  "logos:web-fundamentals",
  "logos:web3js",
  "logos:webassembly",
  "logos:webcomponents",
  "logos:webdriverio",
  "logos:webflow",
  "logos:webhint",
  "logos:webhint-icon",
  "logos:webhooks",
  "logos:webix",
  "logos:webix-icon",
  "logos:webkit",
  "logos:webmin",
  "logos:webpack",
  "logos:webplatform",
  "logos:webrtc",
  "logos:websocket",
  "logos:webstorm",
  "logos:webtask",
  "logos:webtorrent",
  "logos:weebly",
  "logos:wercker",
  "logos:whalar",
  "logos:whalar-icon",
  "logos:whatsapp",
  "logos:whatsapp-icon",
  "logos:whatsapp-monochrome-icon",
  "logos:whatwg",
  "logos:wicket",
  "logos:wicket-icon",
  "logos:wifi",
  "logos:wildfly",
  "logos:windi-css",
  "logos:wire",
  "logos:wiredtree",
  "logos:wix",
  "logos:wmr",
  "logos:woocommerce",
  "logos:woocommerce-icon",
  "logos:woopra",
  "logos:wordpress",
  "logos:wordpress-icon",
  "logos:wordpress-icon-alt",
  "logos:workboard",
  "logos:workplace",
  "logos:workplace-icon",
  "logos:wpengine",
  "logos:wufoo",
  "logos:x-ray-goggles",
  "logos:xamarin",
  "logos:xampp",
  "logos:xcart",
  "logos:xero",
  "logos:xplenty",
  "logos:xray-for-jira",
  "logos:xstate",
  "logos:xtend",
  "logos:xwiki",
  "logos:xwiki-icon",
  "logos:yahoo",
  "logos:yaml",
  "logos:yammer",
  "logos:yandex-ru",
  "logos:yarn",
  "logos:ycombinator",
  "logos:yeoman",
  "logos:yii",
  "logos:youtrack",
  "logos:youtube",
  "logos:youtube-icon",
  "logos:zabbix",
  "logos:zapier",
  "logos:zapier-icon",
  "logos:zeit",
  "logos:zeit-icon",
  "logos:zend-framework",
  "logos:zendesk",
  "logos:zendesk-icon",
  "logos:zenhub",
  "logos:zenhub-icon",
  "logos:zeplin",
  "logos:zeroheight",
  "logos:zeroheight-icon",
  "logos:zest",
  "logos:zig",
  "logos:zigbee",
  "logos:zoho",
  "logos:zoom",
  "logos:zoom-icon",
  "logos:zorin-os",
  "logos:zube",
  "logos:zulip",
  "logos:zulip-icon",
  "logos:zwave",
];

export default logoiconNames;
