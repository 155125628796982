import { setApiBase } from "../../entities/app.entity";
import {
  getLanguageByCode,
  getLanguageCode,
  usePlatformData,
} from "../../entities/platform.entity";
import { languageEntity, setLanguage } from "../../entities/translation.entity";
import Select from "../atoms/form/Select";
import Spin from "../atoms/Spin";

function LanguageBox() {
  const { languages } = usePlatformData();
  const { language } = languageEntity.use();

  return (
    <>
      {languages.length > 0 ? (
        <>
          <Select
            fieldSize="small"
            containerClassName="!w-auto !mb-0 !bg-white"
            value={getLanguageByCode(language)}
            onChange={({ target }) => {
              const countryCode = getLanguageCode(target.value);

              setLanguage(countryCode ? countryCode : "en");
              setApiBase(countryCode ? countryCode : "en");
            }}
            options={languages.map((lang: string) => {
              return {
                name: lang.toUpperCase(),
                value: lang,
              };
            })}
          />
        </>
      ) : (
        <>
          <Spin />
        </>
      )}
    </>
  );
}

export default LanguageBox;
