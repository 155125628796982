import { MouseEventHandler } from "react";
import { authEntity } from "../../../entities/auth.entity";
import {
  toggleSupportModal,
  toggleSupportButton,
} from "../../../entities/support.entity";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

type SupportMenuType = {
  name: string;
  action?: MouseEventHandler<HTMLSpanElement>;
  requireAuth?: boolean;
};

const SupportMenu = () => {
  const auth = authEntity.use();

  const navigate = useNavigate();

  const supportMenu: SupportMenuType[] = [
    {
      name: "Find answers",
      action: () => {
        toggleSupportModal("find-answers");
      },
    },
    {
      name: "Contact us",
      action: () => {
        toggleSupportModal("contact-us");
      },
    },
    {
      name: "Suggest an improvement",
      requireAuth: true,
      action: () => {
        toggleSupportModal("suggestion");
      },
    },
    {
      name: "Support Page",
      requireAuth: true,
      action: () => {
        navigate("/support");
        toggleSupportButton();
      },
    },
  ];

  const filteredMenu = supportMenu.filter((item) =>
    !auth ? !item.requireAuth : true
  );

  return (
    <div className="bg-white flex flex-col items-stretch text-left shadow mb-3 rounded-lg py-1 border border-dark border-opacity-10 min-w-[10rem]">
      {filteredMenu.map((item) => (
        <span
          onClick={item.action ? item.action : () => undefined}
          key={`support-menu-${item.name}`}
          className="px-3 text-dark py-1 cursor-pointer hover:bg-dark hover:bg-opacity-10 hover:text-darkest"
        >
          {t(item.name)}
        </span>
      ))}
    </div>
  );
};

export default SupportMenu;
