import { Dialog, Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";
import clsx from "clsx";
import React, { Fragment, ReactNode } from "react";

const Modal = ({
  onClose,
  isOpen,
  children,
  title,
  maxWidth,
  noClose,
}: {
  onClose: () => void;
  isOpen: boolean;
  children: ReactNode;
  title?: string | ReactNode;
  maxWidth?: string;
  noClose?: boolean;
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[90]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto ">
          <div className="flex mt-20  justify-center md:p-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  "mx-4 w-full bg-white transform overflow-hidden rounded-2xl  p-6 text-left align-middle transition-all ",
                  maxWidth ? maxWidth : "max-w-md"
                )}
              >
                <div className="flex justify-between gap-4 items-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 flex grow "
                  >
                    {title}
                  </Dialog.Title>

                  {noClose ? (
                    ""
                  ) : (
                    <Icon
                      className={`w-10 h-10 md:w-6 md:h-6 text-dark cursor-pointer`}
                      icon="heroicons:x-circle"
                      onClick={onClose}
                    />
                  )}
                </div>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
