import clsx from "clsx";
import React from "react";
import { generateId } from "../../../../utils/helpers";
import "./Checkbox.scss";

const Checkbox = ({
  label,
  checked,
  bold,
  ...props
}: {
  label: string;
  checked?: boolean;
  bold?: boolean;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>) => {
  const id = React.useMemo(() => generateId(), []);

  return (
    <label
      htmlFor={id}
      className="relative inline-flex items-center gap-2 cursor-pointer"
    >
      <input
        {...{ ...props, id, type: "checkbox", checked }}
        className="sr-only peer"
      />
      <i className="shrink-0 relative transition-all border border-[#151b1b] rounded size-4 peer-checked:border-pif-teal before:absolute before:size-5/6 before:rounded-sm peer-checked:before:bg-pif-teal before:transition-all before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 after:absolute after:w-1/2 after:h-5/6 after:border-transparent after:border-r-2 after:border-b-2 after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-[60%] after:rotate-45 after:scale-[.6] peer-checked:after:border-white after:transition-all after:delay-75" />
      {label && (
        <span
          className={clsx(
            "text-sm text-main-black",
            bold ? "font-semibold" : "font-medium"
          )}
        >
          {label}
        </span>
      )}
    </label>
  );
};

export default Checkbox;
