import { toggleSupportModal } from "../../../entities/support.entity";
import Input from "../../atoms/form/Input";
import Textarea from "../../atoms/form/Textarea";
import Button from "../../atoms/Button";
import SupportSelect from "../../atoms/form/SupportSelect";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

const SuggestionContent = () => {
  const categoryOptions = [
    "Piff",
    "Supplier",
    "Products Category",
    "Transactions",
    "Others",
  ];
  const urgencyOptions = ["Nice-to-have", "Important", "Critical"];
  const { t } = useTranslation();

  return (
    <div className="bg-white shadow w-full max-h-[35rem] rounded-lg flex flex-col justify-between">
      <h1 className="py-3 px-4 bg-darker-teal rounded-t-lg text-white text-lg font-semibold flex items-center justify-between">
        <span></span>
        <span>{t("Suggest an Improvement")}</span>

        <button onClick={() => toggleSupportModal(null)}>
          <Icon icon="heroicons:x-mark" />
        </button>
      </h1>

      <div className="flex-grow overflow-y-auto p-4 space-y-3">
        <div className="space-y-1">
          <h1 className="font-medium text-darker-teal mt-0 text-heading-2">
            {t("Category")}
          </h1>
          <SupportSelect options={categoryOptions} />
        </div>
        <div className="space-y-1">
          <h1 className="font-medium text-darker-teal mt-0 text-heading-2">
            {t("Urgency")}
          </h1>
          <SupportSelect options={urgencyOptions} />
        </div>
        <div className="space-y-1">
          <h1 className="text-heading-2 font-medium text-darker-teal mt-0">
            {t("Title")}
          </h1>
          <Input fieldSize="small" />
        </div>
        <div className="space-y-1">
          <h1 className="text-heading-2 font-medium text-darker-teal mt-0">
            {t("Description")}
          </h1>
          <Textarea rows={4} />
        </div>
      </div>

      <div className="py-3 px-4 flex justify-between">
        <Button
          label={t("Close")}
          isOutline={true}
          onClick={() => toggleSupportModal(null)}
        />
        <Button
          label="Request feature"
          onClick={() => toggleSupportModal(null)}
        />
      </div>
    </div>
  );
};

export default SuggestionContent;
