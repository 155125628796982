import { ReactNode, useRef } from "react";
import { useClickOutside } from "@reactuses/core";
import clsx from "clsx";

const Modal = ({
  children,
  open,
  close,
  width,
}: {
  children: ReactNode;
  open: boolean;
  width?: string;
  close: () => void; // Update the type of the 'close' prop
}) => {
  const ref = useRef(null);

  useClickOutside(ref, () => {
    close();
  });

  return (
    <>
      {open && (
        <section className="fixed inset-0 bg-light-tint bg-opacity-60 h-screen w-screen flex flex-col items-center pt-20 z-40">
          <div
            ref={ref}
            className={clsx(
              "w-11/12  md:mx-auto",
              width ? `md:${width}` : "md:w-1/3"
            )}
          >
            {children}
          </div>
        </section>
      )}
    </>
  );
};

export default Modal;
