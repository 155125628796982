import i18n, { use as useI18n } from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "./en.json";
import esTranslations from "./es.json";
import daTranslations from "./da.json";
import svTranslations from "./sv.json";
import itTranslations from "./it.json";
import ukTranslations from "./uk.json";

export const resources = {
  en: {
    translation: enTranslations,
  },
  es: {
    translation: esTranslations,
  },
  da: {
    translation: daTranslations,
  },
  sv: {
    translation: svTranslations,
  },
  it: {
    translation: itTranslations,
  },
  uk: {
    translation: ukTranslations,
  },
};

useI18n(initReactI18next).init({
  resources,
  lng: localStorage.getItem("language") || "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export const changeLanguage = i18n.changeLanguage;

export default i18n;
