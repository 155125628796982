export const resetScreenZoom = () => {
  if (
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i)
  ) {
    const viewportmeta = document.querySelector('meta[name="viewport"]');
    if (viewportmeta) {
      viewportmeta.setAttribute(
        "content",
        "width=device-width, minimum-scale=1.0, maximum-scale=1.0, initial-scale=1.0, shrink-to-fit=no"
      );
      viewportmeta.setAttribute(
        "content",
        "width=device-width, minimum-scale=1.0, maximum-scale=1.0, initial-scale=1.0, shrink-to-fit=no"
      );
    }
  }
};
