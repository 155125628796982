import React, { useRef } from "react";
import clsx from "clsx";

interface RippleButtonProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  className?: string;
  rippleColor?: string;
  rounded?: string;
}

const Ripple: React.FC<RippleButtonProps> = ({
  children,
  className,
  rippleColor = "bg-white",
  rounded = "rounded",
  ...props
}) => {
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const button = buttonRef.current;

    if (!button) {
      return;
    }

    const ripple = document.createElement("span");

    ripple.className = clsx(
      "ripple absolute rounded-full w-12 h-12 bg-opacity-0 animate-ripple bg-opacity-50",
      rippleColor
    );
    ripple.style.left = `${event.clientX - button.offsetLeft}px`;
    ripple.style.top = `${event.clientY - button.offsetTop}px`;

    button.appendChild(ripple);

    setTimeout(() => {
      ripple.remove();
    }, 1000);
  };

  return (
    <div
      ref={buttonRef}
      {...props}
      onClick={handleClick}
      className={clsx(
        "Ripple relative overflow-hidden p-0 border-none cursor-pointer outline-none transition-colors duration-300",
        rounded,
        className
      )}
    >
      {children}
    </div>
  );
};

export default Ripple;
