/* eslint-disable import/named */
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  deleteToken,
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyByQieu9l8gJaSfAJCGR9EWDyfHkmvIz-g",
  authDomain: "pif-dashboard.firebaseapp.com",
  projectId: "pif-dashboard",
  storageBucket: "pif-dashboard.appspot.com",
  messagingSenderId: "1000958045316",
  appId: "1:1000958045316:web:7cb61e0c508c1a6d54a6d1",
  measurementId: "G-J0TCKVYDQP",
};

export const app = initializeApp(firebaseConfig);

export const fireMessaging = getMessaging(app);

const VAPID_KEY =
  "BHryrHAA1wPrk4apBaE1G1EXuNxzgLgoMQf6kT6Iad9dssx0Vk7LLEruyMAoWU-k1EyA-asasXXFBZPoQbM30Ms";

export const generateFireMessageToken = async () => {
  const isSupportedBrowser = await isSupported();

  if (!isSupportedBrowser) return null;

  try {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const token = await getToken(fireMessaging, {
        vapidKey: VAPID_KEY,
      });

      return token;
    } else {
      console.log("Permission denied");
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteFireBaseToken = async () => {
  await deleteToken(fireMessaging);
};
