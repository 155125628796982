import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { allAppRoutes } from ".";
import NotFoundPage from "../pages/404";

export default function AppRouter() {
  const computedRoutes = allAppRoutes.map((route) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const { noAuth, routeName, sideBarLink, bottomSideLink, icon, ...rest } =
    // route;
    const { path, element } = route;

    return { path, element };
  });

  const router = createBrowserRouter([
    ...computedRoutes,
    {
      path: "*",
      caseSensitive: true,
      element: <NotFoundPage />,
    },
  ]);

  return <RouterProvider router={router} />;
}
