import { Icon } from "@iconify/react";
import clsx from "clsx";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import Dropdown from "../../../components/molecules/Dropdown";
import { requestEnd, requestStart } from "../../../entities/app.entity";
import { faqEntity } from "../../../entities/cms.entity";
import {
  getLanguageByCode,
  getLanguageCode,
  usePlatformData,
} from "../../../entities/platform.entity";
import {
  supportEntity,
  toggleSupportModal,
} from "../../../entities/support.entity";
import { fetchFromApi, handleFetchError } from "../../../services";
import { FaqInterface } from "../../../types/global.types";
import parse from "html-react-parser";

const FaqContent = () => {
  const { openedModal } = supportEntity.use();
  const faqs = faqEntity.use();
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [fetchedFaq, setFetchedFaq] = useState<FaqInterface[]>();
  const [searchValue, setSearchValue] = useState("");
  const { languages } = usePlatformData();

  const fetchFaq = async () => {
    requestStart("fetch-support-faqs");
    try {
      const { data } = await fetchFromApi({
        path: "cms/faq",
      });
      setFetchedFaq(data.data);
    } catch (error) {
      handleFetchError(error);
    } finally {
      requestEnd();
    }
  };
  useEffect(() => {
    fetchFaq();
  }, []);

  return (
    <div className={clsx("bg-white shadow rounded-lg flex flex-col")}>
      <h1 className="py-3 px-4 bg-darker-teal rounded-t-lg text-white text-lg font-semibold flex items-center justify-between">
        <span>Find Answers</span>

        <button onClick={() => toggleSupportModal(null)}>
          <Icon
            icon={
              openedModal === "find-answers-modal"
                ? "heroicons:x-mark"
                : "heroicons:minus"
            }
          />
        </button>
      </h1>
      <br />
      <Dropdown
        initialOption={getLanguageByCode(selectedLanguage)}
        options={languages.map((value) => ({ value, name: capitalize(value) }))}
        onChange={(lang) => setSelectedLanguage(getLanguageCode(lang) || "")}
      />
      <div className="flex-grow overflow-y-auto">
        {fetchedFaq &&
          fetchedFaq?.length > 0 &&
          fetchedFaq
            ?.filter((elem) =>
              elem.question[selectedLanguage]
                ?.toLowerCase()
                .includes(searchValue.toLocaleLowerCase().trim())
            )
            .map((faq, faqIndex) => (
              <div
                className={clsx(
                  "px-3 py-4",
                  faqIndex !== faqs.length - 1 && "border-b"
                )}
                key={`support-faq-${faq.answer}`}
              >
                <h1 className="font-semibold mb-1 text-darker-teal mt-0">
                  {faq.question[selectedLanguage]}
                </h1>
                <p className="text-sm text-darkest">
                  {parse(faq.answer[selectedLanguage] as string)}
                </p>
              </div>
            ))}
      </div>
      <form
        onSubmit={() => null}
        className="flex border-t items-center mt-1 pr-2 text-darkest"
      >
        <input
          type="input"
          placeholder="Search our FAQ"
          className="flex-grow py-2 px-3 self-stretch outline-none border-none rounded-b-lg"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Icon icon="heroicons:magnifying-glass" className="h-6 w-6" />
      </form>
    </div>
  );
};

export default FaqContent;
