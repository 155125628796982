import clsx from "clsx";
import { DetailedHTMLProps, useState } from "react";
import { useTranslation } from "react-i18next";
import { productsEntity } from "../../entities/products.entity";
import ConfirmSwitchFromEditProduct from "../../pages/Products/SingleProduct/ConfirmSwitchFromEditProduct";

type propsType = {
  tabs: Array<{ name: string; id: string } | string>;
  activeTab?: number;
  setActiveTab: (activeTab: number) => void;
} & DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Tab = ({ activeTab, setActiveTab, tabs, ...props }: propsType) => {
  const [closeConfirmation, setCloseConfirmation] = useState(false);
  const [clickedTab, setClickedTab] = useState(activeTab);
  const { t } = useTranslation();
  const { visitedEditProduct } = productsEntity.use();

  return (
    <div
      className={`bg-white flex space-x-8 border-b px-4 md:px-8 select-none overflow-x-auto `}
      {...props}
    >
      {tabs.map((tabItem, tabIndex) => {
        return (
          <span
            key={
              typeof tabItem === "string" ? `TabItem-${tabIndex}` : tabItem.id
            }
            className={clsx(
              "border-b-2 whitespace-nowrap cursor-pointer pt-4 pb-3 font-medium",
              activeTab == tabIndex
                ? "text-teal-500 border-teal-500"
                : "border-transparent text-dark"
            )}
            onClick={() => {
              if (visitedEditProduct) {
                setCloseConfirmation(true);
                setClickedTab(tabIndex);
              } else {
                setActiveTab(tabIndex);
              }
            }}
          >
            {typeof tabItem === "string" ? t(tabItem) : t(tabItem.name)}
          </span>
        );
      })}
      <ConfirmSwitchFromEditProduct
        isOpen={closeConfirmation}
        onClose={() => setCloseConfirmation(false)}
        clickedTab={clickedTab || 0}
        setActiveTab={setActiveTab}
      />
    </div>
  );
};

export default Tab;
