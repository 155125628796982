import clsx from "clsx";

const Spin = ({ className }: { className?: string }) => (
  <div
    className={clsx(
      "w-4 border-2 border-white rounded-full aspect-square !border-r-transparent animate-spin-fast",
      className
    )}
  />
);

export default Spin;
