import { entity, persistence } from "simpler-state";
import { appStateType, ToastType } from "../types/global.types";

import { generateId } from "../utils/helpers";

// const baseUrl = "http://pif-test.eu-north-1.elasticbeanstalk.com/v1/";
const baseUrl = "https://pif-staging-a6152c0342af.herokuapp.com/v1/";

const defaultState: appStateType = {
  baseApi: `${baseUrl}en`,
  redirectTo: "/dashboard",
  toasts: [],
  loader: {
    active: false,
    theme: "light",
  },
  requesting: {
    caller: "PIF",
    status: false,
  },
  currentMarketplace: "",
  timeFilter: "all_time",
  featureFlag: {
    exportCSV: false,
  },
};

export const appEntity = entity(defaultState, [persistence("pif-dash-app")]);

// actions
export const setPendingRedirect = (path: string) =>
  appEntity.set((currentState) => ({
    ...currentState,
    redirectTo: path.startsWith("/auth") ? "/dashboard" : path,
  }));

export const setApiBase = (language: string) =>
  appEntity.set((currentState) => ({
    ...currentState,
    baseApi: `${baseUrl}${language}`,
  }));
export const clearPendingRedirect = () =>
  appEntity.set((currentState) => ({
    ...currentState,
    redirectTo: "/dashboard",
  }));

export const showGlobalLoader = (theme: "light" | "dark" = "light") =>
  appEntity.set((currenState) => ({
    ...currenState,
    loader: {
      active: true,
      theme,
    },
  }));
export const hideGlobalLoader = () =>
  appEntity.set((currenState) => ({
    ...currenState,
    loader: {
      active: false,
      theme: "light",
    },
  }));

export const removeToast = (toastId: string, duration = 10000) => {
  setTimeout(() => {
    appEntity.set((currentState) => {
      return {
        ...currentState,
        toasts: currentState.toasts.map((toast) => {
          if (toast.id === toastId) toast.active = false;
          return toast;
        }),
      };
    });
    appEntity.set((currentState) => ({
      ...currentState,
      toasts: currentState.toasts.filter((toast) => toast.id !== toastId),
    }));
  }, duration);
};

export const toast = (
  message: string,
  type: ToastType["type"] = "success",
  body?: string
) => {
  const toastId = generateId();

  appEntity.set((currentState) => ({
    ...currentState,
    toasts: [
      ...currentState.toasts,
      {
        message,
        ...(body && { body }),
        id: toastId,
        active: true,
        type,
      },
    ],
  }));
  removeToast(toastId, 5000);
};

export const requestStart = (caller: "global" | string = "PIF") =>
  appEntity.set((state) => ({
    ...state,
    requesting: { caller, status: true },
  }));

export const requestEnd = () =>
  appEntity.set((state) => ({ ...state, requesting: defaultState.requesting }));

export const setCurrentMarketplace = (marketplace: string) =>
  appEntity.set((state) => ({ ...state, currentMarketplace: marketplace }));

export const setTimeFilter = (time: appStateType["timeFilter"]) =>
  appEntity.set((state) => ({ ...state, timeFilter: time }));
