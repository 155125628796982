const heroiconNames = [
  "heroicons:academic-cap",
  "heroicons:academic-cap-20-solid",
  "heroicons:academic-cap-solid",
  "heroicons:adjustments-horizontal",
  "heroicons:adjustments-horizontal-20-solid",
  "heroicons:adjustments-horizontal-solid",
  "heroicons:adjustments-vertical",
  "heroicons:adjustments-vertical-20-solid",
  "heroicons:adjustments-vertical-solid",
  "heroicons:archive-box",
  "heroicons:archive-box-20-solid",
  "heroicons:archive-box-arrow-down",
  "heroicons:archive-box-arrow-down-20-solid",
  "heroicons:archive-box-arrow-down-solid",
  "heroicons:archive-box-solid",
  "heroicons:archive-box-x-mark",
  "heroicons:archive-box-x-mark-20-solid",
  "heroicons:archive-box-x-mark-solid",
  "heroicons:arrow-down",
  "heroicons:arrow-down-20-solid",
  "heroicons:arrow-down-circle",
  "heroicons:arrow-down-circle-20-solid",
  "heroicons:arrow-down-circle-solid",
  "heroicons:arrow-down-left",
  "heroicons:arrow-down-left-20-solid",
  "heroicons:arrow-down-left-solid",
  "heroicons:arrow-down-on-square",
  "heroicons:arrow-down-on-square-20-solid",
  "heroicons:arrow-down-on-square-solid",
  "heroicons:arrow-down-on-square-stack",
  "heroicons:arrow-down-on-square-stack-20-solid",
  "heroicons:arrow-down-on-square-stack-solid",
  "heroicons:arrow-down-right",
  "heroicons:arrow-down-right-20-solid",
  "heroicons:arrow-down-right-solid",
  "heroicons:arrow-down-solid",
  "heroicons:arrow-down-tray",
  "heroicons:arrow-down-tray-20-solid",
  "heroicons:arrow-down-tray-solid",
  "heroicons:arrow-left",
  "heroicons:arrow-left-20-solid",
  "heroicons:arrow-left-circle",
  "heroicons:arrow-left-circle-20-solid",
  "heroicons:arrow-left-circle-solid",
  "heroicons:arrow-left-on-rectangle",
  "heroicons:arrow-left-on-rectangle-20-solid",
  "heroicons:arrow-left-on-rectangle-solid",
  "heroicons:arrow-left-solid",
  "heroicons:arrow-long-down",
  "heroicons:arrow-long-down-20-solid",
  "heroicons:arrow-long-down-solid",
  "heroicons:arrow-long-left",
  "heroicons:arrow-long-left-20-solid",
  "heroicons:arrow-long-left-solid",
  "heroicons:arrow-long-right",
  "heroicons:arrow-long-right-20-solid",
  "heroicons:arrow-long-right-solid",
  "heroicons:arrow-long-up",
  "heroicons:arrow-long-up-20-solid",
  "heroicons:arrow-long-up-solid",
  "heroicons:arrow-path",
  "heroicons:arrow-path-20-solid",
  "heroicons:arrow-path-rounded-square",
  "heroicons:arrow-path-rounded-square-20-solid",
  "heroicons:arrow-path-rounded-square-solid",
  "heroicons:arrow-path-solid",
  "heroicons:arrow-right",
  "heroicons:arrow-right-20-solid",
  "heroicons:arrow-right-circle",
  "heroicons:arrow-right-circle-20-solid",
  "heroicons:arrow-right-circle-solid",
  "heroicons:arrow-right-on-rectangle",
  "heroicons:arrow-right-on-rectangle-20-solid",
  "heroicons:arrow-right-on-rectangle-solid",
  "heroicons:arrow-right-solid",
  "heroicons:arrow-small-down",
  "heroicons:arrow-small-down-20-solid",
  "heroicons:arrow-small-down-solid",
  "heroicons:arrow-small-left",
  "heroicons:arrow-small-left-20-solid",
  "heroicons:arrow-small-left-solid",
  "heroicons:arrow-small-right",
  "heroicons:arrow-small-right-20-solid",
  "heroicons:arrow-small-right-solid",
  "heroicons:arrow-small-up",
  "heroicons:arrow-small-up-20-solid",
  "heroicons:arrow-small-up-solid",
  "heroicons:arrow-top-right-on-square",
  "heroicons:arrow-top-right-on-square-20-solid",
  "heroicons:arrow-top-right-on-square-solid",
  "heroicons:arrow-trending-down",
  "heroicons:arrow-trending-down-20-solid",
  "heroicons:arrow-trending-down-solid",
  "heroicons:arrow-trending-up",
  "heroicons:arrow-trending-up-20-solid",
  "heroicons:arrow-trending-up-solid",
  "heroicons:arrow-up",
  "heroicons:arrow-up-20-solid",
  "heroicons:arrow-up-circle",
  "heroicons:arrow-up-circle-20-solid",
  "heroicons:arrow-up-circle-solid",
  "heroicons:arrow-up-left",
  "heroicons:arrow-up-left-20-solid",
  "heroicons:arrow-up-left-solid",
  "heroicons:arrow-up-on-square",
  "heroicons:arrow-up-on-square-20-solid",
  "heroicons:arrow-up-on-square-solid",
  "heroicons:arrow-up-on-square-stack",
  "heroicons:arrow-up-on-square-stack-20-solid",
  "heroicons:arrow-up-on-square-stack-solid",
  "heroicons:arrow-up-right",
  "heroicons:arrow-up-right-20-solid",
  "heroicons:arrow-up-right-solid",
  "heroicons:arrow-up-solid",
  "heroicons:arrow-up-tray",
  "heroicons:arrow-up-tray-20-solid",
  "heroicons:arrow-up-tray-solid",
  "heroicons:arrow-uturn-down",
  "heroicons:arrow-uturn-down-20-solid",
  "heroicons:arrow-uturn-down-solid",
  "heroicons:arrow-uturn-left",
  "heroicons:arrow-uturn-left-20-solid",
  "heroicons:arrow-uturn-left-solid",
  "heroicons:arrow-uturn-right",
  "heroicons:arrow-uturn-right-20-solid",
  "heroicons:arrow-uturn-right-solid",
  "heroicons:arrow-uturn-up",
  "heroicons:arrow-uturn-up-20-solid",
  "heroicons:arrow-uturn-up-solid",
  "heroicons:arrows-pointing-in",
  "heroicons:arrows-pointing-in-20-solid",
  "heroicons:arrows-pointing-in-solid",
  "heroicons:arrows-pointing-out",
  "heroicons:arrows-pointing-out-20-solid",
  "heroicons:arrows-pointing-out-solid",
  "heroicons:arrows-right-left",
  "heroicons:arrows-right-left-20-solid",
  "heroicons:arrows-right-left-solid",
  "heroicons:arrows-up-down",
  "heroicons:arrows-up-down-20-solid",
  "heroicons:arrows-up-down-solid",
  "heroicons:at-symbol",
  "heroicons:at-symbol-20-solid",
  "heroicons:at-symbol-solid",
  "heroicons:backspace",
  "heroicons:backspace-20-solid",
  "heroicons:backspace-solid",
  "heroicons:backward",
  "heroicons:backward-20-solid",
  "heroicons:backward-solid",
  "heroicons:banknotes",
  "heroicons:banknotes-20-solid",
  "heroicons:banknotes-solid",
  "heroicons:bars-2",
  "heroicons:bars-2-20-solid",
  "heroicons:bars-2-solid",
  "heroicons:bars-3",
  "heroicons:bars-3-20-solid",
  "heroicons:bars-3-bottom-left",
  "heroicons:bars-3-bottom-left-20-solid",
  "heroicons:bars-3-bottom-left-solid",
  "heroicons:bars-3-bottom-right",
  "heroicons:bars-3-bottom-right-20-solid",
  "heroicons:bars-3-bottom-right-solid",
  "heroicons:bars-3-center-left",
  "heroicons:bars-3-center-left-20-solid",
  "heroicons:bars-3-center-left-solid",
  "heroicons:bars-3-solid",
  "heroicons:bars-4",
  "heroicons:bars-4-20-solid",
  "heroicons:bars-4-solid",
  "heroicons:bars-arrow-down",
  "heroicons:bars-arrow-down-20-solid",
  "heroicons:bars-arrow-down-solid",
  "heroicons:bars-arrow-up",
  "heroicons:bars-arrow-up-20-solid",
  "heroicons:bars-arrow-up-solid",
  "heroicons:battery-0",
  "heroicons:battery-0-20-solid",
  "heroicons:battery-0-solid",
  "heroicons:battery-100",
  "heroicons:battery-100-20-solid",
  "heroicons:battery-100-solid",
  "heroicons:battery-50",
  "heroicons:battery-50-20-solid",
  "heroicons:battery-50-solid",
  "heroicons:beaker",
  "heroicons:beaker-20-solid",
  "heroicons:beaker-solid",
  "heroicons:bell",
  "heroicons:bell-20-solid",
  "heroicons:bell-alert",
  "heroicons:bell-alert-20-solid",
  "heroicons:bell-alert-solid",
  "heroicons:bell-slash",
  "heroicons:bell-slash-20-solid",
  "heroicons:bell-slash-solid",
  "heroicons:bell-snooze",
  "heroicons:bell-snooze-20-solid",
  "heroicons:bell-snooze-solid",
  "heroicons:bell-solid",
  "heroicons:bolt",
  "heroicons:bolt-20-solid",
  "heroicons:bolt-slash",
  "heroicons:bolt-slash-20-solid",
  "heroicons:bolt-slash-solid",
  "heroicons:bolt-solid",
  "heroicons:book-open",
  "heroicons:book-open-20-solid",
  "heroicons:book-open-solid",
  "heroicons:bookmark",
  "heroicons:bookmark-20-solid",
  "heroicons:bookmark-slash",
  "heroicons:bookmark-slash-20-solid",
  "heroicons:bookmark-slash-solid",
  "heroicons:bookmark-solid",
  "heroicons:bookmark-square",
  "heroicons:bookmark-square-20-solid",
  "heroicons:bookmark-square-solid",
  "heroicons:briefcase",
  "heroicons:briefcase-20-solid",
  "heroicons:briefcase-solid",
  "heroicons:bug-ant",
  "heroicons:bug-ant-20-solid",
  "heroicons:bug-ant-solid",
  "heroicons:building-library",
  "heroicons:building-library-20-solid",
  "heroicons:building-library-solid",
  "heroicons:building-office",
  "heroicons:building-office-2",
  "heroicons:building-office-2-20-solid",
  "heroicons:building-office-2-solid",
  "heroicons:building-office-20-solid",
  "heroicons:building-office-solid",
  "heroicons:building-storefront",
  "heroicons:building-storefront-20-solid",
  "heroicons:building-storefront-solid",
  "heroicons:cake",
  "heroicons:cake-20-solid",
  "heroicons:cake-solid",
  "heroicons:calculator",
  "heroicons:calculator-20-solid",
  "heroicons:calculator-solid",
  "heroicons:calendar",
  "heroicons:calendar-20-solid",
  "heroicons:calendar-days",
  "heroicons:calendar-days-20-solid",
  "heroicons:calendar-days-solid",
  "heroicons:calendar-solid",
  "heroicons:camera",
  "heroicons:camera-20-solid",
  "heroicons:camera-solid",
  "heroicons:chart-bar",
  "heroicons:chart-bar-20-solid",
  "heroicons:chart-bar-solid",
  "heroicons:chart-bar-square",
  "heroicons:chart-bar-square-20-solid",
  "heroicons:chart-bar-square-solid",
  "heroicons:chart-pie",
  "heroicons:chart-pie-20-solid",
  "heroicons:chart-pie-solid",
  "heroicons:chat-bubble-bottom-center",
  "heroicons:chat-bubble-bottom-center-20-solid",
  "heroicons:chat-bubble-bottom-center-solid",
  "heroicons:chat-bubble-bottom-center-text",
  "heroicons:chat-bubble-bottom-center-text-20-solid",
  "heroicons:chat-bubble-bottom-center-text-solid",
  "heroicons:chat-bubble-left",
  "heroicons:chat-bubble-left-20-solid",
  "heroicons:chat-bubble-left-ellipsis",
  "heroicons:chat-bubble-left-ellipsis-20-solid",
  "heroicons:chat-bubble-left-ellipsis-solid",
  "heroicons:chat-bubble-left-right",
  "heroicons:chat-bubble-left-right-20-solid",
  "heroicons:chat-bubble-left-right-solid",
  "heroicons:chat-bubble-left-solid",
  "heroicons:chat-bubble-oval-left",
  "heroicons:chat-bubble-oval-left-20-solid",
  "heroicons:chat-bubble-oval-left-ellipsis",
  "heroicons:chat-bubble-oval-left-ellipsis-20-solid",
  "heroicons:chat-bubble-oval-left-ellipsis-solid",
  "heroicons:chat-bubble-oval-left-solid",
  "heroicons:check",
  "heroicons:check-20-solid",
  "heroicons:check-badge",
  "heroicons:check-badge-20-solid",
  "heroicons:check-badge-solid",
  "heroicons:check-circle",
  "heroicons:check-circle-20-solid",
  "heroicons:check-circle-solid",
  "heroicons:check-solid",
  "heroicons:chevron-double-down",
  "heroicons:chevron-double-down-20-solid",
  "heroicons:chevron-double-down-solid",
  "heroicons:chevron-double-left",
  "heroicons:chevron-double-left-20-solid",
  "heroicons:chevron-double-left-solid",
  "heroicons:chevron-double-right",
  "heroicons:chevron-double-right-20-solid",
  "heroicons:chevron-double-right-solid",
  "heroicons:chevron-double-up",
  "heroicons:chevron-double-up-20-solid",
  "heroicons:chevron-double-up-solid",
  "heroicons:chevron-down",
  "heroicons:chevron-down-20-solid",
  "heroicons:chevron-down-solid",
  "heroicons:chevron-left",
  "heroicons:chevron-left-20-solid",
  "heroicons:chevron-left-solid",
  "heroicons:chevron-right",
  "heroicons:chevron-right-20-solid",
  "heroicons:chevron-right-solid",
  "heroicons:chevron-up",
  "heroicons:chevron-up-20-solid",
  "heroicons:chevron-up-down",
  "heroicons:chevron-up-down-20-solid",
  "heroicons:chevron-up-down-solid",
  "heroicons:chevron-up-solid",
  "heroicons:circle-stack",
  "heroicons:circle-stack-20-solid",
  "heroicons:circle-stack-solid",
  "heroicons:clipboard",
  "heroicons:clipboard-20-solid",
  "heroicons:clipboard-document",
  "heroicons:clipboard-document-20-solid",
  "heroicons:clipboard-document-check",
  "heroicons:clipboard-document-check-20-solid",
  "heroicons:clipboard-document-check-solid",
  "heroicons:clipboard-document-list",
  "heroicons:clipboard-document-list-20-solid",
  "heroicons:clipboard-document-list-solid",
  "heroicons:clipboard-document-solid",
  "heroicons:clipboard-solid",
  "heroicons:clock",
  "heroicons:clock-20-solid",
  "heroicons:clock-solid",
  "heroicons:cloud",
  "heroicons:cloud-20-solid",
  "heroicons:cloud-arrow-down",
  "heroicons:cloud-arrow-down-20-solid",
  "heroicons:cloud-arrow-down-solid",
  "heroicons:cloud-arrow-up",
  "heroicons:cloud-arrow-up-20-solid",
  "heroicons:cloud-arrow-up-solid",
  "heroicons:cloud-solid",
  "heroicons:code-bracket",
  "heroicons:code-bracket-20-solid",
  "heroicons:code-bracket-solid",
  "heroicons:code-bracket-square",
  "heroicons:code-bracket-square-20-solid",
  "heroicons:code-bracket-square-solid",
  "heroicons:cog",
  "heroicons:cog-20-solid",
  "heroicons:cog-6-tooth",
  "heroicons:cog-6-tooth-20-solid",
  "heroicons:cog-6-tooth-solid",
  "heroicons:cog-8-tooth",
  "heroicons:cog-8-tooth-20-solid",
  "heroicons:cog-8-tooth-solid",
  "heroicons:cog-solid",
  "heroicons:command-line",
  "heroicons:command-line-20-solid",
  "heroicons:command-line-solid",
  "heroicons:computer-desktop",
  "heroicons:computer-desktop-20-solid",
  "heroicons:computer-desktop-solid",
  "heroicons:cpu-chip",
  "heroicons:cpu-chip-20-solid",
  "heroicons:cpu-chip-solid",
  "heroicons:credit-card",
  "heroicons:credit-card-20-solid",
  "heroicons:credit-card-solid",
  "heroicons:cube",
  "heroicons:cube-20-solid",
  "heroicons:cube-solid",
  "heroicons:cube-transparent",
  "heroicons:cube-transparent-20-solid",
  "heroicons:cube-transparent-solid",
  "heroicons:currency-bangladeshi",
  "heroicons:currency-bangladeshi-20-solid",
  "heroicons:currency-bangladeshi-solid",
  "heroicons:currency-dollar",
  "heroicons:currency-dollar-20-solid",
  "heroicons:currency-dollar-solid",
  "heroicons:currency-euro",
  "heroicons:currency-euro-20-solid",
  "heroicons:currency-euro-solid",
  "heroicons:currency-pound",
  "heroicons:currency-pound-20-solid",
  "heroicons:currency-pound-solid",
  "heroicons:currency-rupee",
  "heroicons:currency-rupee-20-solid",
  "heroicons:currency-rupee-solid",
  "heroicons:currency-yen",
  "heroicons:currency-yen-20-solid",
  "heroicons:currency-yen-solid",
  "heroicons:cursor-arrow-rays",
  "heroicons:cursor-arrow-rays-20-solid",
  "heroicons:cursor-arrow-rays-solid",
  "heroicons:cursor-arrow-ripple",
  "heroicons:cursor-arrow-ripple-20-solid",
  "heroicons:cursor-arrow-ripple-solid",
  "heroicons:device-phone-mobile",
  "heroicons:device-phone-mobile-20-solid",
  "heroicons:device-phone-mobile-solid",
  "heroicons:device-tablet",
  "heroicons:device-tablet-20-solid",
  "heroicons:device-tablet-solid",
  "heroicons:document",
  "heroicons:document-20-solid",
  "heroicons:document-arrow-down",
  "heroicons:document-arrow-down-20-solid",
  "heroicons:document-arrow-down-solid",
  "heroicons:document-arrow-up",
  "heroicons:document-arrow-up-20-solid",
  "heroicons:document-arrow-up-solid",
  "heroicons:document-chart-bar",
  "heroicons:document-chart-bar-20-solid",
  "heroicons:document-chart-bar-solid",
  "heroicons:document-check",
  "heroicons:document-check-20-solid",
  "heroicons:document-check-solid",
  "heroicons:document-duplicate",
  "heroicons:document-duplicate-20-solid",
  "heroicons:document-duplicate-solid",
  "heroicons:document-magnifying-glass",
  "heroicons:document-magnifying-glass-20-solid",
  "heroicons:document-magnifying-glass-solid",
  "heroicons:document-minus",
  "heroicons:document-minus-20-solid",
  "heroicons:document-minus-solid",
  "heroicons:document-plus",
  "heroicons:document-plus-20-solid",
  "heroicons:document-plus-solid",
  "heroicons:document-solid",
  "heroicons:document-text",
  "heroicons:document-text-20-solid",
  "heroicons:document-text-solid",
  "heroicons:ellipsis-horizontal",
  "heroicons:ellipsis-horizontal-20-solid",
  "heroicons:ellipsis-horizontal-circle",
  "heroicons:ellipsis-horizontal-circle-20-solid",
  "heroicons:ellipsis-horizontal-circle-solid",
  "heroicons:ellipsis-horizontal-solid",
  "heroicons:ellipsis-vertical",
  "heroicons:ellipsis-vertical-20-solid",
  "heroicons:ellipsis-vertical-solid",
  "heroicons:envelope",
  "heroicons:envelope-20-solid",
  "heroicons:envelope-open",
  "heroicons:envelope-open-20-solid",
  "heroicons:envelope-open-solid",
  "heroicons:envelope-solid",
  "heroicons:exclamation-circle",
  "heroicons:exclamation-circle-20-solid",
  "heroicons:exclamation-circle-solid",
  "heroicons:exclamation-triangle",
  "heroicons:exclamation-triangle-20-solid",
  "heroicons:exclamation-triangle-solid",
  "heroicons:eye",
  "heroicons:eye-20-solid",
  "heroicons:eye-dropper",
  "heroicons:eye-dropper-20-solid",
  "heroicons:eye-dropper-solid",
  "heroicons:eye-slash",
  "heroicons:eye-slash-20-solid",
  "heroicons:eye-slash-solid",
  "heroicons:eye-solid",
  "heroicons:face-frown",
  "heroicons:face-frown-20-solid",
  "heroicons:face-frown-solid",
  "heroicons:face-smile",
  "heroicons:face-smile-20-solid",
  "heroicons:face-smile-solid",
  "heroicons:film",
  "heroicons:film-20-solid",
  "heroicons:film-solid",
  "heroicons:finger-print",
  "heroicons:finger-print-20-solid",
  "heroicons:finger-print-solid",
  "heroicons:fire",
  "heroicons:fire-20-solid",
  "heroicons:fire-solid",
  "heroicons:flag",
  "heroicons:flag-20-solid",
  "heroicons:flag-solid",
  "heroicons:folder",
  "heroicons:folder-20-solid",
  "heroicons:folder-arrow-down",
  "heroicons:folder-arrow-down-20-solid",
  "heroicons:folder-arrow-down-solid",
  "heroicons:folder-minus",
  "heroicons:folder-minus-20-solid",
  "heroicons:folder-minus-solid",
  "heroicons:folder-open",
  "heroicons:folder-open-20-solid",
  "heroicons:folder-open-solid",
  "heroicons:folder-plus",
  "heroicons:folder-plus-20-solid",
  "heroicons:folder-plus-solid",
  "heroicons:folder-solid",
  "heroicons:forward",
  "heroicons:forward-20-solid",
  "heroicons:forward-solid",
  "heroicons:funnel",
  "heroicons:funnel-20-solid",
  "heroicons:funnel-solid",
  "heroicons:gif",
  "heroicons:gif-20-solid",
  "heroicons:gif-solid",
  "heroicons:gift",
  "heroicons:gift-20-solid",
  "heroicons:gift-solid",
  "heroicons:gift-top",
  "heroicons:gift-top-20-solid",
  "heroicons:gift-top-solid",
  "heroicons:globe-alt",
  "heroicons:globe-alt-20-solid",
  "heroicons:globe-alt-solid",
  "heroicons:globe-americas",
  "heroicons:globe-americas-20-solid",
  "heroicons:globe-americas-solid",
  "heroicons:globe-asia-australia",
  "heroicons:globe-asia-australia-20-solid",
  "heroicons:globe-asia-australia-solid",
  "heroicons:globe-europe-africa",
  "heroicons:globe-europe-africa-20-solid",
  "heroicons:globe-europe-africa-solid",
  "heroicons:hand-raised",
  "heroicons:hand-raised-20-solid",
  "heroicons:hand-raised-solid",
  "heroicons:hand-thumb-down",
  "heroicons:hand-thumb-down-20-solid",
  "heroicons:hand-thumb-down-solid",
  "heroicons:hand-thumb-up",
  "heroicons:hand-thumb-up-20-solid",
  "heroicons:hand-thumb-up-solid",
  "heroicons:hashtag",
  "heroicons:hashtag-20-solid",
  "heroicons:hashtag-solid",
  "heroicons:heart",
  "heroicons:heart-20-solid",
  "heroicons:heart-solid",
  "heroicons:home",
  "heroicons:home-20-solid",
  "heroicons:home-modern",
  "heroicons:home-modern-20-solid",
  "heroicons:home-modern-solid",
  "heroicons:home-solid",
  "heroicons:identification",
  "heroicons:identification-20-solid",
  "heroicons:identification-solid",
  "heroicons:inbox",
  "heroicons:inbox-20-solid",
  "heroicons:inbox-arrow-down",
  "heroicons:inbox-arrow-down-20-solid",
  "heroicons:inbox-arrow-down-solid",
  "heroicons:inbox-solid",
  "heroicons:inbox-stack",
  "heroicons:inbox-stack-20-solid",
  "heroicons:inbox-stack-solid",
  "heroicons:information-circle",
  "heroicons:information-circle-20-solid",
  "heroicons:information-circle-solid",
  "heroicons:key",
  "heroicons:key-20-solid",
  "heroicons:key-solid",
  "heroicons:language",
  "heroicons:language-20-solid",
  "heroicons:language-solid",
  "heroicons:lifebuoy",
  "heroicons:lifebuoy-20-solid",
  "heroicons:lifebuoy-solid",
  "heroicons:light-bulb",
  "heroicons:light-bulb-20-solid",
  "heroicons:light-bulb-solid",
  "heroicons:link",
  "heroicons:link-20-solid",
  "heroicons:link-solid",
  "heroicons:list-bullet",
  "heroicons:list-bullet-20-solid",
  "heroicons:list-bullet-solid",
  "heroicons:lock-closed",
  "heroicons:lock-closed-20-solid",
  "heroicons:lock-closed-solid",
  "heroicons:lock-open",
  "heroicons:lock-open-20-solid",
  "heroicons:lock-open-solid",
  "heroicons:magnifying-glass",
  "heroicons:magnifying-glass-20-solid",
  "heroicons:magnifying-glass-circle",
  "heroicons:magnifying-glass-circle-20-solid",
  "heroicons:magnifying-glass-circle-solid",
  "heroicons:magnifying-glass-minus",
  "heroicons:magnifying-glass-minus-20-solid",
  "heroicons:magnifying-glass-minus-solid",
  "heroicons:magnifying-glass-plus",
  "heroicons:magnifying-glass-plus-20-solid",
  "heroicons:magnifying-glass-plus-solid",
  "heroicons:magnifying-glass-solid",
  "heroicons:map",
  "heroicons:map-20-solid",
  "heroicons:map-pin",
  "heroicons:map-pin-20-solid",
  "heroicons:map-pin-solid",
  "heroicons:map-solid",
  "heroicons:megaphone",
  "heroicons:megaphone-20-solid",
  "heroicons:megaphone-solid",
  "heroicons:microphone",
  "heroicons:microphone-20-solid",
  "heroicons:microphone-solid",
  "heroicons:minus",
  "heroicons:minus-20-solid",
  "heroicons:minus-circle",
  "heroicons:minus-circle-20-solid",
  "heroicons:minus-circle-solid",
  "heroicons:minus-small",
  "heroicons:minus-small-20-solid",
  "heroicons:minus-small-solid",
  "heroicons:minus-solid",
  "heroicons:moon",
  "heroicons:moon-20-solid",
  "heroicons:moon-solid",
  "heroicons:musical-note",
  "heroicons:musical-note-20-solid",
  "heroicons:musical-note-solid",
  "heroicons:newspaper",
  "heroicons:newspaper-20-solid",
  "heroicons:newspaper-solid",
  "heroicons:no-symbol",
  "heroicons:no-symbol-20-solid",
  "heroicons:no-symbol-solid",
  "heroicons:paint-brush",
  "heroicons:paint-brush-20-solid",
  "heroicons:paint-brush-solid",
  "heroicons:paper-airplane",
  "heroicons:paper-airplane-20-solid",
  "heroicons:paper-airplane-solid",
  "heroicons:paper-clip",
  "heroicons:paper-clip-20-solid",
  "heroicons:paper-clip-solid",
  "heroicons:pause",
  "heroicons:pause-20-solid",
  "heroicons:pause-circle",
  "heroicons:pause-circle-20-solid",
  "heroicons:pause-circle-solid",
  "heroicons:pause-solid",
  "heroicons:pencil",
  "heroicons:pencil-20-solid",
  "heroicons:pencil-solid",
  "heroicons:pencil-square",
  "heroicons:pencil-square-20-solid",
  "heroicons:pencil-square-solid",
  "heroicons:phone",
  "heroicons:phone-20-solid",
  "heroicons:phone-arrow-down-left",
  "heroicons:phone-arrow-down-left-20-solid",
  "heroicons:phone-arrow-down-left-solid",
  "heroicons:phone-arrow-up-right",
  "heroicons:phone-arrow-up-right-20-solid",
  "heroicons:phone-arrow-up-right-solid",
  "heroicons:phone-solid",
  "heroicons:phone-x-mark",
  "heroicons:phone-x-mark-20-solid",
  "heroicons:phone-x-mark-solid",
  "heroicons:photo",
  "heroicons:photo-20-solid",
  "heroicons:photo-solid",
  "heroicons:play",
  "heroicons:play-20-solid",
  "heroicons:play-circle",
  "heroicons:play-circle-20-solid",
  "heroicons:play-circle-solid",
  "heroicons:play-pause",
  "heroicons:play-pause-20-solid",
  "heroicons:play-pause-solid",
  "heroicons:play-solid",
  "heroicons:plus",
  "heroicons:plus-20-solid",
  "heroicons:plus-circle",
  "heroicons:plus-circle-20-solid",
  "heroicons:plus-circle-solid",
  "heroicons:plus-small",
  "heroicons:plus-small-20-solid",
  "heroicons:plus-small-solid",
  "heroicons:plus-solid",
  "heroicons:power",
  "heroicons:power-20-solid",
  "heroicons:power-solid",
  "heroicons:presentation-chart-bar",
  "heroicons:presentation-chart-bar-20-solid",
  "heroicons:presentation-chart-bar-solid",
  "heroicons:presentation-chart-line",
  "heroicons:presentation-chart-line-20-solid",
  "heroicons:presentation-chart-line-solid",
  "heroicons:printer",
  "heroicons:printer-20-solid",
  "heroicons:printer-solid",
  "heroicons:puzzle-piece",
  "heroicons:puzzle-piece-20-solid",
  "heroicons:puzzle-piece-solid",
  "heroicons:qr-code",
  "heroicons:qr-code-20-solid",
  "heroicons:qr-code-solid",
  "heroicons:question-mark-circle",
  "heroicons:question-mark-circle-20-solid",
  "heroicons:question-mark-circle-solid",
  "heroicons:queue-list",
  "heroicons:queue-list-20-solid",
  "heroicons:queue-list-solid",
  "heroicons:radio",
  "heroicons:radio-20-solid",
  "heroicons:radio-solid",
  "heroicons:receipt-percent",
  "heroicons:receipt-percent-20-solid",
  "heroicons:receipt-percent-solid",
  "heroicons:receipt-refund",
  "heroicons:receipt-refund-20-solid",
  "heroicons:receipt-refund-solid",
  "heroicons:rectangle-group",
  "heroicons:rectangle-group-20-solid",
  "heroicons:rectangle-group-solid",
  "heroicons:rectangle-stack",
  "heroicons:rectangle-stack-20-solid",
  "heroicons:rectangle-stack-solid",
  "heroicons:rocket-launch",
  "heroicons:rocket-launch-20-solid",
  "heroicons:rocket-launch-solid",
  "heroicons:rss",
  "heroicons:rss-20-solid",
  "heroicons:rss-solid",
  "heroicons:scale",
  "heroicons:scale-20-solid",
  "heroicons:scale-solid",
  "heroicons:scissors",
  "heroicons:scissors-20-solid",
  "heroicons:scissors-solid",
  "heroicons:server",
  "heroicons:server-20-solid",
  "heroicons:server-solid",
  "heroicons:server-stack",
  "heroicons:server-stack-20-solid",
  "heroicons:server-stack-solid",
  "heroicons:share",
  "heroicons:share-20-solid",
  "heroicons:share-solid",
  "heroicons:shield-check",
  "heroicons:shield-check-20-solid",
  "heroicons:shield-check-solid",
  "heroicons:shield-exclamation",
  "heroicons:shield-exclamation-20-solid",
  "heroicons:shield-exclamation-solid",
  "heroicons:shopping-bag",
  "heroicons:shopping-bag-20-solid",
  "heroicons:shopping-bag-solid",
  "heroicons:shopping-cart",
  "heroicons:shopping-cart-20-solid",
  "heroicons:shopping-cart-solid",
  "heroicons:signal",
  "heroicons:signal-20-solid",
  "heroicons:signal-slash",
  "heroicons:signal-slash-20-solid",
  "heroicons:signal-slash-solid",
  "heroicons:signal-solid",
  "heroicons:sparkles",
  "heroicons:sparkles-20-solid",
  "heroicons:sparkles-solid",
  "heroicons:speaker-wave",
  "heroicons:speaker-wave-20-solid",
  "heroicons:speaker-wave-solid",
  "heroicons:speaker-x-mark",
  "heroicons:speaker-x-mark-20-solid",
  "heroicons:speaker-x-mark-solid",
  "heroicons:square-2-stack",
  "heroicons:square-2-stack-20-solid",
  "heroicons:square-2-stack-solid",
  "heroicons:square-3-stack-3d",
  "heroicons:square-3-stack-3d-20-solid",
  "heroicons:square-3-stack-3d-solid",
  "heroicons:squares-2x2",
  "heroicons:squares-2x2-20-solid",
  "heroicons:squares-2x2-solid",
  "heroicons:squares-plus",
  "heroicons:squares-plus-20-solid",
  "heroicons:squares-plus-solid",
  "heroicons:star",
  "heroicons:star-20-solid",
  "heroicons:star-solid",
  "heroicons:stop",
  "heroicons:stop-20-solid",
  "heroicons:stop-circle",
  "heroicons:stop-circle-20-solid",
  "heroicons:stop-circle-solid",
  "heroicons:stop-solid",
  "heroicons:sun",
  "heroicons:sun-20-solid",
  "heroicons:sun-solid",
  "heroicons:swatch",
  "heroicons:swatch-20-solid",
  "heroicons:swatch-solid",
  "heroicons:table-cells",
  "heroicons:table-cells-20-solid",
  "heroicons:table-cells-solid",
  "heroicons:tag",
  "heroicons:tag-20-solid",
  "heroicons:tag-solid",
  "heroicons:ticket",
  "heroicons:ticket-20-solid",
  "heroicons:ticket-solid",
  "heroicons:trash",
  "heroicons:trash-20-solid",
  "heroicons:trash-solid",
  "heroicons:trophy",
  "heroicons:trophy-20-solid",
  "heroicons:trophy-solid",
  "heroicons:truck",
  "heroicons:truck-20-solid",
  "heroicons:truck-solid",
  "heroicons:tv",
  "heroicons:tv-20-solid",
  "heroicons:tv-solid",
  "heroicons:user",
  "heroicons:user-20-solid",
  "heroicons:user-circle",
  "heroicons:user-circle-20-solid",
  "heroicons:user-circle-solid",
  "heroicons:user-group",
  "heroicons:user-group-20-solid",
  "heroicons:user-group-solid",
  "heroicons:user-minus",
  "heroicons:user-minus-20-solid",
  "heroicons:user-minus-solid",
  "heroicons:user-plus",
  "heroicons:user-plus-20-solid",
  "heroicons:user-plus-solid",
  "heroicons:user-solid",
  "heroicons:users",
  "heroicons:users-20-solid",
  "heroicons:users-solid",
  "heroicons:variable",
  "heroicons:variable-20-solid",
  "heroicons:variable-solid",
  "heroicons:video-camera",
  "heroicons:video-camera-20-solid",
  "heroicons:video-camera-slash",
  "heroicons:video-camera-slash-20-solid",
  "heroicons:video-camera-slash-solid",
  "heroicons:video-camera-solid",
  "heroicons:view-columns",
  "heroicons:view-columns-20-solid",
  "heroicons:view-columns-solid",
  "heroicons:viewfinder-circle",
  "heroicons:viewfinder-circle-20-solid",
  "heroicons:viewfinder-circle-solid",
  "heroicons:wallet",
  "heroicons:wallet-20-solid",
  "heroicons:wallet-solid",
  "heroicons:wifi",
  "heroicons:wifi-20-solid",
  "heroicons:wifi-solid",
  "heroicons:window",
  "heroicons:window-20-solid",
  "heroicons:window-solid",
  "heroicons:wrench",
  "heroicons:wrench-20-solid",
  "heroicons:wrench-screwdriver",
  "heroicons:wrench-screwdriver-20-solid",
  "heroicons:wrench-screwdriver-solid",
  "heroicons:wrench-solid",
  "heroicons:x-circle",
  "heroicons:x-circle-20-solid",
  "heroicons:x-circle-solid",
  "heroicons:x-mark",
  "heroicons:x-mark-20-solid",
  "heroicons:x-mark-solid",
];

export default heroiconNames;
