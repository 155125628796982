import { Icon } from "@iconify/react";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

const Stepper = ({
  steps,
  activeStep,
  color,
}: {
  steps: string[];
  activeStep: number;
  color?: string;
}) => {
  const { t } = useTranslation();
  return (
    <ol className="flex gap-2 md:gap-0 items-center justify-center w-full text-sm font-medium text-center sm:text-base">
      {steps.map((el, index) => (
        <li
          key={`${el}-${index}`}
          className={clsx(
            "flex md:w-full items-center font-semibold",
            index + 1 > activeStep
              ? "text-[#AFBBBB]"
              : index + 1 == activeStep
              ? "text-main-black"
              : "text-pif-teal ",
            index !== steps.length - 1 &&
              "after:content-['']  sm:after:inline-block md:after:mx-1  after:w-full after:h-1 after:border-b after:border-dashed  after:border-gray-200 after:border-1 after:hidden after:flex-grow ",
            index === steps.length - 1 && "max-w-max"
          )}
        >
          <span className="flex flex-col items-center whitspace-nowrap sm:after:hidden after:text-gray-200 text-sm ">
            <span
              className={clsx(
                "mr-1 h-6 w-6 flex items-center justify-center rounded-full",
                index + 1 > activeStep
                  ? "bg-[#D9E2E2]"
                  : index + 1 == activeStep
                  ? "bg-[#575D5F] text-white"
                  : " "
              )}
            >
              {index + 1 >= activeStep ? (
                index + 1
              ) : (
                <Icon
                  icon={"heroicons:check-circle-solid"}
                  className="text-pif-teal w-full h-full"
                  style={{ color }}
                />
              )}
            </span>
            <span className="whitespace-nowrap">{t(el)}</span>
          </span>
        </li>
      ))}
    </ol>
  );
};

export default Stepper;
