import { entity, persistence } from "simpler-state";
import { changeLanguage } from "../translation/i18n";
import { setBaseUrl } from "./base.url.entity";

const defaultState: {
  language: string;
} = {
  language: "en",
};

export const languageEntity = entity(defaultState, [persistence("language")]);

export const setLanguage = (lang: string) => {
  changeLanguage(lang);
  setBaseUrl(lang);
  languageEntity.set((currentState) => ({
    ...currentState,
    language: lang,
  }));
};
