import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authEntity, clearAuth } from "../../entities/auth.entity";
import LanguageBox from "../organisms/LanguageBox";
import SupportWidget from "../organisms/SupportWidget";
import { fetchPlatformPublic } from "../../entities/platform.entity";

const AuthLayout = ({ children }: { children: ReactNode }) => {
  const auth = authEntity.use();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = location.pathname;
    if (
      auth &&
      (currentPath.includes("partner-invite") ||
        currentPath.includes("invitation"))
    ) {
      clearAuth();
      navigate(currentPath);
      return;
    }
    if (auth) navigate("/dashboard", { replace: true });

    document.title = "PIF - Login";

    (async () => {
      await fetchPlatformPublic();
    })();
  }, []);

  return (
    <div className="flex flex-col items-center h-screen py-12 overflow-y-auto text-white bg-dark-teal">
      <div className="w-full mb-10">
        <div className="flex items-center justify-between w-11/12 mx-auto md:w-10/12 max-w-screen-2xl">
          <div className="flex items-center gap-2 shrink-0">
            <img
              {...{ src: "/svgs/pif-logo.svg", alt: "PIF", loading: "lazy" }}
              className="w-auto h-14"
            />
            <span className="font-bold md:text-lg opacity-90">
              PAY IT FORWARD
            </span>
          </div>
          <LanguageBox />
        </div>
      </div>
      {children}
      <SupportWidget />
    </div>
  );
};

export default AuthLayout;
