import clsx from "clsx";
import { appEntity } from "../../entities/app.entity";

const GlobalLoader = () => {
  const { loader } = appEntity.use();

  if (!loader.active) return null;

  const { theme } = loader;

  return (
    <div className="fixed top-0 left-0 w-full flex flex-col">
      <span
        className={clsx(
          "w-[50%] h-[2px] rounded animate-grow",
          theme === "light" ? "bg-pif-teal" : "bg-white"
        )}
      />
      <span
        className={clsx(
          "h-4 w-4 border-2 border-r-transparent m-2 rounded-full animate-spin-fast",
          theme === "light" ? "border-pif-teal" : "border-white"
        )}
      />
    </div>
  );
};

export default GlobalLoader;
