import { useEffect, useState } from "react";
import AuthLayout from "../../../components/templates/AuthLayout";
import Stepper from "../../../components/atoms/Stepper";
import { useApi } from "../../../services";
import { useParams, useSearchParams } from "react-router-dom";
import { saveBrandInfo } from "../../../entities/partner-reg.entity";
import FootballClubInvitation from "./FootballClubInvitation";
import BrandProductInvitation from "./BrandProductInvitation";
import { t } from "i18next";

const AcceptBrandInvitation = () => {
  const { brand } = useParams();
  const steps = ["Supplier’s Details", "Settlement Details", "Add Product"];

  const [activeStep, setActiveStep] = useState<number>(3);
  const [searchParams] = useSearchParams();

  const tabParam = searchParams.get("has_product");

  const brandInfo = useApi({
    path: "brands/pif-id",
    queryParams: { brand_pif_id: brand || "" },
  });

  //   if (brandInfo.err) navigate("/auth/login");
  useEffect(() => {
    saveBrandInfo(brandInfo.data);
  }, [brandInfo.data]);

  return (
    <AuthLayout>
      {tabParam ? (
        <div className=" mb-5 w-full md:w-[52.5rem] rounded-3xl px-4 md:px-8 pb-8 bg-white flex flex-grow text-dark flex-col">
          <img
            src={brandInfo?.data?.logoUrl}
            className="w-14 h-14 inline-block mx-auto mt-8"
            alt=""
          />
          <div className="mt-4 text-center">
            <h2
              className="text-main-black font-semibold text-2xl"
              style={{ color: brandInfo?.data?.brandColor }}
            >
              {t("Become a")}
              <span className="px-2">{brandInfo?.data?.name}</span>
              {t("brand partner")}
            </h2>
            <h5 className="mt-2 mb-10">
              {t("Becoming a brand partner on PIF is as simple as 1..2..3!")}
            </h5>
            <div className="mx-36">
              <Stepper
                color={brandInfo?.data?.brandColor}
                steps={steps}
                activeStep={activeStep}
              />
            </div>
            <div className="mt-10">
              {activeStep === 3 && brandInfo?.data?.isStrictlyBrandProducts ? (
                <BrandProductInvitation brand={brandInfo?.data} />
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <FootballClubInvitation brand={brandInfo?.data} />
      )}
    </AuthLayout>
  );
};

export default AcceptBrandInvitation;
