import { useState } from "react";
import Tab from "../../components/organisms/Tab";
import { Icon } from "@iconify/react";
import IconList from "./IconList";
import EmptyState from "../../components/organisms/EmptyState";
import { Link } from "react-router-dom";

export const Stylguide = () => {
  const tabs = ["Icons", "General"];
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="h-screen">
      <div className="h-12 flex items-center px-6 space-x-4">
        <Link to="/">
          <img src="/images/pif_logo.png" alt="PIF" />
        </Link>
        <span className="text-lg uppercase font-medium text-pif-teal">
          Styleguide
        </span>
      </div>
      <Tab
        tabs={tabs}
        setActiveTab={(tab) => setActiveTab(tab)}
        activeTab={activeTab}
      />

      {activeTab === 0 ? (
        <IconList />
      ) : (
        <EmptyState
          heading={
            <div className="flex items-center space-x-2">
              <span>Styleguide | Work in Progress</span>
              <Icon icon="heroicons-outline:cog-6-tooth" />
            </div>
          }
          description="We're working on it"
        />
      )}
    </div>
  );
};
