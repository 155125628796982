import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.scss";
import Hotjar from "@hotjar/browser";

const siteId = 3598313;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
