import { Icon } from "@iconify/react";
import clsx from "clsx";
import { appEntity, removeToast } from "../../entities/app.entity";
import { useEffect, useState } from "react";
import { ToastType } from "../../types/global.types";

const ToastWidget = () => {
  const toasts = appEntity.use((state) => state.toasts);
  const [activeToasts, setActiveToasts] = useState<ToastType[]>([]);

  useEffect(() => {
    // If this message already exists , remove it

    const uniqueToasts: ToastType[] = toasts.filter(
      (toast: ToastType, index, self: ToastType[]) => {
        return self.findIndex((t) => t.message === toast.message) === index;
      }
    );
    setActiveToasts(uniqueToasts);
  }, [toasts]);

  return (
    <div className="fixed bottom-8 grid gap-y-4 pr-6 pt-5 z-[100] left-4 	transform transition-all duration-500 ease-in-out">
      {activeToasts.map((toast, toastIndex) => (
        <div
          className="min-w-[23.6875rem] max-w-full  md:max-w-5xl min-h-[3rem] z-50 drop-shadow px-4 rounded-xl bg-white flex items-center"
          key={`toast-${toastIndex}`}
        >
          <div
            className={clsx(
              "w-8 h-8 flex items-center justify-center rounded-full",
              toast.type == "success" ? "bg-pif-teal/20" : "bg-accent-pink/20"
            )}
          >
            <Icon
              stroke="4"
              color={toast.type == "success" ? "#295251" : "#FF0033"}
              icon={
                toast.type == "success"
                  ? "heroicons:check-20-solid"
                  : "heroicons:information-circle"
              }
            />
          </div>
          <div
            className={
              "flex-grow justify-between flex items-center pl-5  rounded-r-lg"
            }
          >
            <div>
              <span className="flex-grow mr-[4.5625rem] text-darkest">
                {toast.message}
              </span>
            </div>
            <div
              onClick={() => removeToast(toast.id, 0)}
              className="cursor-pointer w-6 h-6 rounded-full bg-white flex items-center justify-center"
            >
              <Icon
                icon={"heroicons:x-mark-solid"}
                stroke="4"
                color={toast.type == "success" ? "#4DB0AD" : "#FF0033"}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ToastWidget;
