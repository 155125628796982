import dayjs from "dayjs";
import { useState } from "react";
import { ISignupProductForm } from "../types/global.types";

export const usePartnerSignupProductValidation = (
  quantityLimitType: string,
  language: string,
  isTaxFree: boolean,
  selectedCategories: any,
  images: string[]
) => {
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

  const validateForm = (form: ISignupProductForm) => {
    const errors: { [key: string]: string } = {};

    // Check required fields
    if (!form.name[language]) {
      errors.name = "Product name is required";
    }
    if (!form.description[language]) {
      errors.description = "Description is required";
    }
    if (selectedCategories.length === 0) {
      errors.categories = "Category is required";
    }
    if (form.productType !== "free-gift" && !form.price) {
      errors.price = "Product price is required";
    }
    if (!form.validThru) {
      errors.validThru = "Validity period is required";
    }
    if (
      form.redemptionValidityType == "date" &&
      !form.redemptionValidityValue
    ) {
      errors.redemptionValidityValue = "Choose validity date";
    }
    if (
      form.redemptionValidityType == "period" &&
      !form.redemptionValidityValue
    ) {
      errors.redemptionValidityValue = "Period value is required";
    }
    if (images.length < 1) {
      errors.images = "Upload atleast one image";
    }

    if (!isTaxFree && form.tax < 1) {
      errors.tax = "Value can't be zero";
    }
    if (quantityLimitType == "Limited" && !form.quantity) {
      errors.quantity = "Quantity is required";
    }
    if (quantityLimitType == "Limited" && !form.quantityAlert) {
      errors.quantityAlert = "Quantity limit alert is required";
    }
    const validThruDate = dayjs(form.validThru);
    const redemptionDate = dayjs(form.redemptionValidityValue);
    if (
      form.redemptionValidityType == "date" &&
      redemptionDate.isBefore(validThruDate)
    ) {
      errors.redemptionDate = `This date should be after "valid thru" date`;
    }
    if (
      form.redemptionValidityType == "date" &&
      redemptionDate.isBefore(validThruDate)
    ) {
      errors.validThruDate = `This date should be before "redemption validity" date`;
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0; // Returns true if there are no errors
  };

  return { formErrors, validateForm };
};

export const useBrandSignupProductValidation = (
  quantityLimitType: string,
  isTaxFree: boolean
) => {
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

  const validateForm = (form: ISignupProductForm) => {
    const errors: { [key: string]: string } = {};

    // Check required fields

    if (form.productType !== "free-gift" && !form.price) {
      errors.price = "Product price is required";
    }
    if (!form.validThru) {
      errors.validThru = "Validity period is required";
    }
    if (
      form.redemptionValidityType == "date" &&
      !form.redemptionValidityValue
    ) {
      errors.redemptionValidityValue = "Choose validity date";
    }
    if (
      form.redemptionValidityType == "period" &&
      !form.redemptionValidityValue
    ) {
      errors.redemptionValidityValue = "Period value is required";
    }

    if (!isTaxFree && form.tax < 1) {
      errors.tax = "Value can't be zero";
    }
    if (quantityLimitType == "Limited" && !form.quantity) {
      errors.quantity = "Quantity is required";
    }
    if (quantityLimitType == "Limited" && !form.quantityAlert) {
      errors.quantityAlert = "Quantity limit alert is required";
    }
    const validThruDate = dayjs(form.validThru);
    const redemptionDate = dayjs(form.redemptionValidityValue);
    if (
      form.redemptionValidityType == "date" &&
      redemptionDate.isBefore(validThruDate)
    ) {
      errors.redemptionValidityValue = `This date should be after "valid thru" date`;
    }
    if (
      form.redemptionValidityType == "date" &&
      redemptionDate.isBefore(validThruDate)
    ) {
      errors.validThru = `This date should be before "redemption validity" date`;
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0; // Returns true if there are no errors
  };

  return { formErrors, validateForm };
};

export const useFCSignupProductValidation = (
  quantityLimitType: string,
  language: string,
  isTaxFree: boolean,
  selectedCategories: any,
  images: string[]
) => {
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

  const validateForm = (form: ISignupProductForm) => {
    const errors: { [key: string]: string } = {};

    // Check required fields
    if (!form.name[language]) {
      errors.name = "Product name is required";
    }
    if (!form.description[language]) {
      errors.description = "Description is required";
    }
    if (form.productType !== "free-gift" && !form.price) {
      errors.price = "Product price is required";
    }
    if (!form.validThru) {
      errors.validThru = "Validity period is required";
    }
    if (
      form.redemptionValidityType == "date" &&
      !form.redemptionValidityValue
    ) {
      errors.redemptionValidityValue = "Choose validity date";
    }
    if (
      form.redemptionValidityType == "period" &&
      !form.redemptionValidityValue
    ) {
      errors.redemptionValidityValue = "Period value is required";
    }
    if (images.length < 1) {
      errors.images = "Upload atleast one image";
    }

    if (!isTaxFree && form.tax < 1) {
      errors.tax = "Value can't be zero";
    }
    if (quantityLimitType == "Limited" && !form.quantity) {
      errors.quantity = "Quantity is required";
    }
    if (quantityLimitType == "Limited" && !form.quantityAlert) {
      errors.quantityAlert = "Quantity limit alert is required";
    }
    const validThruDate = dayjs(form.validThru);
    const redemptionDate = dayjs(form.redemptionValidityValue);
    if (
      form.redemptionValidityType == "date" &&
      redemptionDate.isBefore(validThruDate)
    ) {
      errors.redemptionDate = `This date should be after "valid thru" date`;
    }
    if (
      form.redemptionValidityType == "date" &&
      redemptionDate.isBefore(validThruDate)
    ) {
      errors.validThruDate = `This date should be before "redemption validity" date`;
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0; // Returns true if there are no errors
  };

  return { formErrors, validateForm };
};
